import React, { useEffect, useState } from "react";  

import {  
    ContentRight,
    ContentRightItem,
    ContentRightTitle,
    CardOption, 
    CardOptionResponse, 
    CardOptionTitle,  
    CardOptionDecoration,
    CardOptionDecorationIcon,
    CardFormBetweeRow
} from "./styled";


import Input from "components/Form/Input";
import { FormAction, FormActions, FormContainer, GeneratedAction } from "ui/styled";
import Button from "components/Form/Button";
import CustomButton from "components/Form/CustomButton";

export default function DashboardCardContent({ toggleSelected, isSelected, item, updateSuggestion }){  

    const [open, setOpen] = useState(false)


    const [ form, setForm ] = useState({ ...item })
    const formValue = ref => { return form?.[ref] ? form?.[ref] : '' ;}
    const changeForm = ( value, ref ) => { setForm({ ...form, [ref]: value }) ;} 

    const save = () => {
        setOpen(false)
        updateSuggestion({
            ...form,
            correct: form?.answer
        })
    }

    useEffect(() => {
        setForm({ ...item, answer: item?.correct })
    }, [item])

    return ( 
        <>
            <CardOption onClick={() => toggleSelected(item)}>
                {
                    !isSelected(item) ? null : 
                    <CardOptionDecoration>
                        <CardOptionDecorationIcon />
                    </CardOptionDecoration>
                }
                <CardOptionResponse>
                    { item?.question?.split("\n")?.map(m => <>
                        { m } <br />
                    </>) }
                </CardOptionResponse>
                {/* <CardOptionResponse>
                    Resposta: <b>{ item?.correct ? "Certo" : "Errado"}</b>
                </CardOptionResponse>
                <CardOptionResponse>
                    Feedback: { item?.feedback }
                </CardOptionResponse> */}

                {
                    !open ? 
                        <GeneratedAction className="edit-button">
                            <Button small outline primary onClick={() => setOpen(true)}>Editar</Button>
                        </GeneratedAction> 
                    : 
                        <FormContainer>
                            <Input type="textarea" placeholder="Pergunta" value={formValue("question")} onChange={e => changeForm(e.target.value, "question")} />
                            {/* <Input type="textarea" placeholder="Feedback" value={formValue("feedback")} onChange={e => changeForm(e.target.value, "feedback")} /> */}

                            <CardFormBetweeRow>
                                {/* <ContentRight>
                                    <ContentRightTitle>A resposta para esta pergunta é:</ContentRightTitle>
                                    <ContentRightItem active={!!formValue("answer")} onClick={(v) => changeForm(true, "answer")}>Verdadeiro</ContentRightItem>
                                    <ContentRightItem active={!formValue("answer")} onClick={(v) => changeForm(false, "answer")}>Falso</ContentRightItem>
                                </ContentRight>  */}
                                <FormAction>
                                    <CustomButton onClick={save}>Salvar</CustomButton>
                                </FormAction> 
                            </CardFormBetweeRow>

                        </FormContainer>
                }

            </CardOption>              
        </>
    );
}