import React, { useContext, useEffect, useState } from "react";  

import {  
    CardHome,
    CardHomeDecoration,
    CardHomeTitle,
    CardQuestion,
    CardQuestionButton,
    CardQuestionButtonContainer,
    CardQuestionDecoration,
    CardQuestionIcon,
    CardQuestionIconDecoration,
    CardQuestionSeed,
    CardQuestionTitle,
    ContentFilter,
    ContentFilterContainer,
    ContentFilterContent,
    ContentFilterSeed,
    ContentFilterTitle,
    DashboardTitle,
    FullLineFilter,
    FullLineFilterInput,
    ImageSpacer
} from "./styled";

import ContainerAuthenticated from "containers/Authenticated";
import { Row, Col } from "reactstrap";

import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Delete, ReadByUser } from "services/students";
import { CoreContext } from "context/CoreContext";
import { exposeStrapiError, normalizeStrapiList, normalizeStrapiRegister, parseStrapiImage } from "utils";
import { mockedQuestions } from "utils/options";
import Button from "components/Form/Button";
import { BetweenRow, Load, LoadCenter, MaxButton } from "ui/styled";
import { toast } from "react-toastify";
import { modalityColors } from "ui/theme-color";
import Input from "components/Form/Input";

export default function DashboardContentStudents(){  

    const history = useHistory();
    const navigate = to => history.push(`/${ to }`);

    const { user } = useContext(CoreContext)

    const [questions, setQuestions] = useState([]) 
    const [loading, setLoading] = useState(false)  

    const [filterModality, setFilterModality] = useState(null)
    const [filterSeed, setFilterSeed] = useState(null)
    
    const [searchSeed, setSearchSeed] = useState("")

    const init = async () => {
        setLoading(true)
        const result = await ReadByUser(user?.id)
        if(result && !exposeStrapiError(result)){
            const normalResult = normalizeStrapiList(result)

            const nextQuestions = normalResult
                ?.map( m => {
                    return ({
                        ...m
                    })
                })?.sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime() )

            setQuestions(nextQuestions)
        }
        setLoading(false)
    }

    const remove = async (item) => {
        setLoading(true)
        const result = await Delete(item?.id)
        if(result && !exposeStrapiError(result)){
            toast.success("Removido")
            init()
        }
        setLoading(false)
    }

    const parseTitle = (type) => {
        return mockedQuestions?.find(f => f.type === type)?.title
    }

    const filterQuestions = f => {
        return (
            ( !filterModality || f?.type === filterModality ) &&
            ( !filterSeed || f?.seed === filterSeed?.seed ) 
        )
    }

    const filterSeeds = f => {
        return (
            ( !filterModality || f?.type === filterModality ) &&
            ( !searchSeed || f?.seed?.toLowerCase()?.indexOf(searchSeed?.toLowerCase()) !== -1) 
        )
    }

    useEffect(() => { init() ;}, [])

    return ( 
        <>
            <ContainerAuthenticated>  

                {
                    loading ? <LoadCenter>
                        <Load colored /> 
                    </LoadCenter> : <>
                        <BetweenRow>
                            <DashboardTitle>Acervo de alunos</DashboardTitle>
                            <MaxButton>
                                <Button nospace outline primary onClick={() => navigate("dashboard/creator/students")}>Ir para Criador</Button>
                            </MaxButton>
                        </BetweenRow>    

                        <Row>
                            {
                                questions?.map((item, key) => 
                                    <Col key={key} sm={12} md={6} lg={3}> 
                                        <CardQuestion>
                                            <CardQuestionTitle >
                                                { item?.name }
                                            </CardQuestionTitle>
                                            <CardQuestionButtonContainer className="remove-button">
                                                <CardQuestionButton>
                                                    <Button nospace small outline primary onClick={() => navigate(`dashboard/creator/students/${item?.id}`)}>Editar</Button>
                                                </CardQuestionButton>
                                                <CardQuestionButton>
                                                    <Button nospace small outline primary onClick={() => remove(item)}>Apagar</Button>
                                                </CardQuestionButton>
                                            </CardQuestionButtonContainer>
                                        </CardQuestion>
                                    </Col>
                                )
                            }
                        </Row>
                    </>
                }
    
            </ContainerAuthenticated> 
        </>
    );
}