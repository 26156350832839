import React from "react"; 

import { Row, Col, Container } from 'reactstrap'; 

import Item from './Item'

import {
    MoreTitle,
    MoreText,
    KnowContent,
    BannerTitle,
    BannerText,
    BannerButton,
    BannerTextContainer,
    BannerSubtitle,
    BannerBigSpace,
    BannerPrice
} from './styled' 
import Button from "components/Form/Button";
import DecorationEnd from "../DecorationEnd";
import { goSection } from "utils";

export default function KnowMore(){ 

 
 
    return ( 
        <> 
            <KnowContent>
                <Container>
                    <BannerTitle>AVALIAÇÃO GRATUITA DE 30 DIAS</BannerTitle>
                    <BannerText>Se não ficar satisfeito, cancele o plano à qualquer momento.</BannerText>
                    <BannerButton>
                        <Button outline white onClick={() => goSection("sign-now")}>Assine já</Button>
                    </BannerButton>
                    <BannerTextContainer>
                        <BannerText>Aproveite o preço promocional de lançamento!</BannerText>
                        <BannerPrice />
                        <BannerText>Assine agora e garanta o preço exclusivo de lançamento, em breve o preço será atualizado para <b>R$ 39,90</b>, economize assinando agora mesmo e transforme suas aulas!</BannerText>
                        <BannerSubtitle id="sign-now">Assine Já!</BannerSubtitle>
                    </BannerTextContainer>
                </Container> 
            </KnowContent>
            <DecorationEnd reverse />
        </>
    );
}