import React from "react"; 

import { Row, Col, Container } from 'reactstrap';  

import Item from './Item'

import {
    MoreInfosContainer, 
    
    BannerTitle,
    BannerText,
    
    MoreInfosContainerDecorated, 
} from './styled'
import CardCommom from "../Card/Commom";
import DecorationEnd from "../DecorationEnd";

export default function MoreInfos(){ 
    const moreInfos = [
        // { title:"Tecnologia Inovadora", text: ' ', image: '/images/cover-3.png'  },
        { title:"Experiências Gamifiadas", text: 'Engaje seus alunos com as experiências gamificadas. Transforme o aprendizado em uma aventura emocionante, mantendo a motivação e o interesse dos alunos sempre altos com desafios e recompensas divertidas.', image: '/images/cover-2.png', target:"section-gamification" },
        { title:"Economia de tempo", text: 'Economize tempo. Nossa plataforma automatiza a correção de exercícios e permite o reaproveitamento de conteúdos, liberando mais tempo para você se concentrar no que realmente importa ensinar e inspirar.', image: '/images/cover-1.png', target:"section-economy"  },
    ]

    const cardCommom = {
        image: "/images/app-2.png",
        title:"Ranking dos Alunos",
        text:"Veja quem está no topo e incentive a competição saudável que promove um aprendizado contínuo e dedicado.",
        supertitle:"Motive seus alunos",
        subtitle:"com um sistema de ranking dinâmico.",
        subtext:"",
    }

    return ( 
        <> 
            <MoreInfosContainer> 
                <MoreInfosContainerDecorated>
                    <Container>
                        
                        <Row>
                            <Col></Col>
                            <Col></Col>
                            {
                                moreInfos.map((item, key) => 
                                    <Col key={key} lg={{ size:4 }}> 
                                        <Item variant={key} title={item.title} text={item.text} target={item.target} image={item.image} secondary={ key % 2 !== 0 }/>
                                    </Col>
                                )
                            }
                            <Col></Col>
                            <Col></Col>
                        </Row>


                        
                        <BannerTitle id="section-gamification">Descubra como a Royal Academy pode transformar sua sala de aula.</BannerTitle>
                        <BannerText>Navegue pelas funcionalidades intuitivas do nosso aplicativo e veja como é simples criar um ambiente de aprendizado inovador e eficiente.</BannerText>

                        <CardCommom card={cardCommom} />


                    </Container>
                </MoreInfosContainerDecorated>
            </MoreInfosContainer>
            <DecorationEnd />

        </>
    );
}