import React from "react";  

import {  
    CardHome,
    CardHomeTitle,
    ContentRight,
    ContentRightItem,
    ContentRightTitle,
    DashboardTitle,
    CardOption, 
    CardOptionResponse, 
    CardOptionTitle, 
    CardOptionsWrap, 
    CardOptionsWrapIcon, 
    DashboardSideMascot, 
    DashboardSideMascotBaloon, 
    SimpleRow,
    CardForm,
    CardFormButton,
    CardOptionDecoration,
    CardOptionDecorationIcon,
    CardFormBetweeRow
} from "./styled";

import ContainerAuthenticated from "containers/Authenticated";
import { Row, Col } from "reactstrap";

import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Input from "components/Form/Input";
import useController from "./controller";
import Toggle from "components/Form/Toggle";
import { FormAction, FormActions, FormContainer, GeneratedAction, Load, LoadCenter, ManualAction } from "ui/styled";
import Button from "components/Form/Button";
import ImagedInput from "components/Form/ImagedInput";
import {  } from "components/Dashboard/Side/styled";
import CustomButton from "components/Form/CustomButton";
import DashboardCardRightWrong from "screens/Dashboard/Cards/RightWrong";

export default function CreatorBaloonIA({ generated }){   


    return ( 
        <> 
            <CardOptionsWrap generated={generated?.length} >
                <CardOption>
                    <CardOptionTitle>
                        Gerador de Conteúdo IA
                    </CardOptionTitle> 
                    <CardOptionResponse>
                        Crie conteúdos personalizados com nosso Gerador de Conteúdo IA. Altere e edite da forma que precisar!
                    </CardOptionResponse>
                </CardOption>
            </CardOptionsWrap>                                      
        </>
    );
}