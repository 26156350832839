import React from "react"; 
 
import Banner from "components/Landpage/Banner";
import HowWorks from 'components/Landpage/HowWorks'
import MoreInfos from 'components/Landpage/MoreInfos'
import CommomCases from 'components/Landpage/CommomCases'
import KnowMore from 'components/Landpage/KnowMore'
import LittleMore from 'components/Landpage/LittleMore' 

import ContainerLandpage from "containers/Landpage";
import { BannerSpacer, BannerText, BannerTitle } from "./styled";
import { Container } from "reactstrap";

export default function LegalTerms(){ 
 
    return ( 
        <ContainerLandpage > 
            
            <Container>
                <BannerTitle>Termos de uso</BannerTitle>

                <BannerText>

                    <b>1. Aceitação dos Termos</b>
                    <p>
                        Ao acessar ou usar o Royal Academy, você concorda em estar vinculado a estes Termos de Uso. Se você não concorda com estes termos, não utilize nossos serviços.
                    </p>

                    <b>2. Modificações nos Termos</b>
                    <p>
                        Reservamo-nos o direito de modificar estes Termos de Uso a qualquer momento. As alterações entrarão em vigor imediatamente após a publicação. Seu uso continuado do serviço após a publicação das alterações constitui sua aceitação dos novos termos.
                    </p>

                    <b>3. Uso do Serviço</b>
                    <p>
                        Você concorda em usar o Royal Academy apenas para fins educacionais e de acordo com estes Termos de Uso. Você não deve usar o serviço de qualquer maneira que possa danificar, desativar, sobrecarregar ou prejudicar nossos servidores ou redes.
                    </p>

                    <b>4. Conta de Usuário</b>
                    <p>
                        Para acessar certos recursos, você pode precisar criar uma conta. Você é responsável por manter a confidencialidade das informações da sua conta e por todas as atividades que ocorram sob sua conta.
                    </p>

                    <b>5. Propriedade Intelectual</b>
                    <p>
                        Todo o conteúdo, incluindo textos, gráficos, imagens e software, é de nossa propriedade ou licenciado para nós. Você não pode usar, reproduzir, distribuir ou criar trabalhos derivados sem nossa permissão expressa por escrito.
                    </p>

                    <b>6. Limitação de Responsabilidade</b>
                    <p>
                        Na máxima extensão permitida pela lei, não seremos responsáveis por quaisquer danos diretos, indiretos, incidentais, especiais ou consequenciais decorrentes do uso ou da incapacidade de uso do serviço.
                    </p>

                    <b>7. Coleta e Compartilhamento de Dados</b>
                    <p>
                        Nós não coletamos nem compartilhamos dados com terceiros. Todas as informações, como o nome do aluno, são inseridas pelo professor. Os alunos na plataforma não terão a possibilidade de inserir informações nem imagens.
                    </p>

                    <b>8. Limites de Uso</b>
                    <p>
                        Os professores podem criar até 10 turmas, e o limite para a criação de alunos é de 400 alunos.
                    </p>

                    <b>9. Lei Aplicável</b>
                    <p>
                        Estes Termos de Uso são regidos e interpretados de acordo com as leis Brasileiras. Você concorda em submeter-se à jurisdição exclusiva dos tribunais localizados em Brasília, Distrito Federal.
                    </p>

                    <b>10. Contato</b>
                    <p>
                        Se você tiver alguma dúvida sobre estes Termos de Uso, entre em contato conosco pelo e-mail sac@royalacademy.com.br
                    </p>

                </BannerText>

                <BannerSpacer />

            </Container>
            
        </ContainerLandpage>
    );
}