import styled from 'styled-components' 

 

export const SectionEndDecoration = styled.div.attrs({ 
})`
    display: flex;
    height: 0;
    border-top: 30vh solid ${ props => props.white ? props.theme.palette.colors.white : props.theme.palette.colors.backgrounddark };
    border-right: 100vw solid transparent;
    
    position: relative;
    margin-bottom: -25vh;

    ${
        props => props.white ? `
            background: ${ props.theme.palette.colors.backgrounddark };
        ` : ``
    }

    ${
        props => props.reverse ? `
            border-right: initial;
            border-left: 100vw solid transparent;
        ` : ``
    }
 
`; 

export const SectionPaint = styled.div.attrs({ 
})`

    width: 200px;
    height: 100px; 
    border-radius: 100px / 50px;
    position: absolute;
    top: -33vh;
    left: 80vw;
    z-index: 2;
    transform: rotate( -12deg );
    background: ${props => props.white ? props.theme.palette.colors.white : props.theme.palette.colors.backgrounddark }; 
 

    ${
        props => props.reverse ? `
            top: -36vh;
            left: -100vw;
            transform: rotate( 12deg );
        ` : ``
    }
 
`; 

export const SectionPaintMinor = styled.div.attrs({ 
})`

    width: 120px;
    height: 60px; 
    border-radius: 60px / 30px;
    position: absolute;
    top: -26vh;
    left: 71vw;
    z-index: 2;
    transform: rotate( -9deg );
    background: ${props => props.white ? props.theme.palette.colors.white : props.theme.palette.colors.backgrounddark }; 

    ${
        props => props.reverse ? `
            top: -30vh;
            left: -89vw;
            transform: rotate( 12deg );
        ` : ``
    }
`; 

export const SectionPaintBlobDrip = styled.div.attrs({ 
})`

    position: absolute;
    top: -25vh;
    left: 74vw;
 
 
    ${
        props => props.reverse ? `
            top: -37vh;
            left: -87vw; 
        ` : ``
    }
 

 
    background-color: ${props => props.white ? props.theme.palette.colors.white : props.theme.palette.colors.backgrounddark }; 
    grid-row-start: 4;
    grid-column-start: 8;
    justify-self: center;
    align-self: end;
    z-index: 2;
    


    height: 60px;
    width: 50px;
    margin-top: -20px;
    border-radius: 0 100% 100% 100%;
 

    transform: rotate3d(0,0,1,15deg) skewY(25deg) skewX(-25deg);
    animation: dropOrigin infinite 4s cubic-bezier(1,.35,.1,.95); 
  
    @keyframes slime {
        0% {
            transform: rotate3d(0,0,1,31deg) skewY(25deg) skewX(0deg) translate3d(0,-1vh,0) ;
            height: 60px;
        }
        
        50% { 
            transform: rotate3d(0,0,1,45deg) skewY(2deg) skewX(10deg) translate3d(0.9vh,.7vh,0);
            height: 60px;
            width: 70px;
        }
        
        100% {
            transform: rotate3d(0,0,1,31deg) skewY(25deg) skewX(0deg) translate3d(0,-1vh,0) ;
            height: 60px;
        }
    }
  
    @keyframes drop {
        0% {
            transform: rotate3d(0,0,1,15deg) skewY(25deg) skewX(-25deg) translate3d(0,0vh,0);
            opacity: 1;
        }
        
        50% {
            opacity: 0;
        }
        
        100% {
            transform: rotate3d(0,0,1,15deg) skewY(25deg) skewX(-25deg) translate3d(31vh,40vh,0);
            opacity: 0;
        }
    }
 
    @keyframes dropOrigin {
        0% {
            transform: rotate3d(0,0,1,31deg) skewY(25deg) translate3d(0,0vh,0);
            opacity: 1;
        }
        
        50% {
            opacity: 1;
        }
        60% {
            opacity: 0;
        }
        
        100% {
            transform: rotate3d(0,0,1,31deg) skewY(25deg) translate3d(31vh,40vh,0);
            opacity: 0;
        }
    }

    @keyframes dropAndBack {
        0% {
            transform: rotate3d(0,0,1,31deg) skewY(25deg) translate3d(0,0vh,0);
        }
        
        50% { 
            // transform: rotate3d(0,0,1,31deg) skewY(25deg) translate3d(2vh,3vh,0);
            transform: rotate3d(0,0,1,31deg) skewY(25deg) translate3d(5.5vh,6.5vh,0);
        }
        
        100% { 
            transform: rotate3d(0,0,1,31deg) skewY(25deg) translate3d(0,0vh,0);
        }
    }

`; 