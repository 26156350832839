import React, { useState } from "react"; 
 
import Banner from "components/Landpage/Banner";
import HowWorks from 'components/Landpage/HowWorks'
import MoreInfos from 'components/Landpage/MoreInfos'
import CommomCases from 'components/Landpage/CommomCases'
import KnowMore from 'components/Landpage/KnowMore'
import LittleMore from 'components/Landpage/LittleMore' 

import ContainerLandpage from "containers/Landpage";
import { LandPrereleaseCover, TigerDecorationImage, Title, Text, InfoContent, InfoContentIn, UniIcon, AnimatedText, AnimatedTextContainer } from "./styled";
import moment from "moment/moment";
import { Container } from "reactstrap";

export default function Landpage(){ 

    const today = moment();
    const releaseDate = moment('2024-08-15'); 

    const [blocked, setBlocked] = useState(true)
    const [counter, setCounter] = useState(0)

    const openEaster = () => {
        if(counter < 6){
            setCounter(counter+1)
            return;
        }
        setBlocked(false)
    }

    return ( 
        <ContainerLandpage land> 
            {
                blocked ? <>
                    <LandPrereleaseCover>
                        <InfoContent>
                            <InfoContentIn>
                                <TigerDecorationImage onClick={openEaster} />
                                <Title>Closed Alpha Royal Academy está acontecendo, Open Beta em breve!</Title>
                                <AnimatedTextContainer>
                                    <AnimatedText>
                                        {/*
                                        <Text>
                                            Temos o prazer de anunciar que o tão aguardado 
                                            lançamento do Royal Academy acontecerá em apenas 
                                            <b>{ releaseDate.diff(today, 'days') } dias</b>!
                                        </Text>
                                        <Text>
                                            O Royal Academy representa um <b>marco</b> nas 
                                            metodologias de ensino. Desenvolvido com tecnologia de ponta, 
                                            o Royal Academy está projetado para transformar o seu 
                                            dia a dia como professor.
                                        </Text>
                                        <Text>
                                            Fique atento às nossas redes sociais para mais informações 
                                            e atualizações sobre o lançamento. Não perca essa oportunidade 
                                            única de ser um dos primeiros a experimentar o Royal Academy!
                                        </Text>
                                        <Text>
                                            A contagem regressiva começou – marque no seu calendário 
                                            e prepare-se para uma revolução!
                                        </Text>  
                                        */}
                                    </AnimatedText>
                                </AnimatedTextContainer>
                                {/* <UniIcon onClick={() => window.open("https://unicorntechhouse.com/", "new")}  /> */}
                            </InfoContentIn>
                        </InfoContent>
                    </LandPrereleaseCover>
                </> : <>
                    <Banner /> 
                    <MoreInfos />
                    <CommomCases /> 
                    <KnowMore /> 
                    <LittleMore />  
                    <HowWorks /> 
                </>
            }

 
        </ContainerLandpage>
    );
}