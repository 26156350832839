import React from "react";

import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import Landpage from 'screens/Landpage' 
import NotFound from 'screens/NotFound' 

import Login from 'screens/Authentication/Login' 
import Register from 'screens/Authentication/Register' 
import Forgot from 'screens/Authentication/Forgot' 
import CreatePassword from 'screens/Authentication/CreatePassword' 

import DashboardHome from 'screens/Dashboard/Home' 
import DashboardMe from 'screens/Dashboard/Me' 
import C3 from "screens/C3";
import DashboardCreator from "screens/Dashboard/Creator";
import DashboardCreatorTasks from "screens/Dashboard/Creator/CreatorTasks";
import DashboardCreatorLessons from "screens/Dashboard/Creator/CreatorLessons";
import DashboardCreatorStudents from "screens/Dashboard/Creator/CreatorStudents";
import DashboardCreatorClasses from "screens/Dashboard/Creator/CreatorClasses";
import DashboardCreatorTasksAssociation from "screens/Dashboard/Creator/CreatorGame/Association";
import DashboardCreatorGameMultiChoice from "screens/Dashboard/Creator/CreatorGame/MultiChoice";
import DashboardCreatorTaskContent from "screens/Dashboard/Creator/CreatorGame/Content";
import DashboardCreatorTaskContentVideo from "screens/Dashboard/Creator/CreatorGame/ContentVideo";
import DashboardCreatorTasksCrossWord from "screens/Dashboard/Creator/CreatorGame/CrossWord";
import DashboardCreatorTasksGibbet from "screens/Dashboard/Creator/CreatorGame/Gibbet";
import DashboardCreatorTasksMemory from "screens/Dashboard/Creator/CreatorGame/Memory";
import DashboardCreatorTasksRightWrong from "screens/Dashboard/Creator/CreatorGame/RightWrong";
import DashboardPlan from "screens/Dashboard/Plan";
import DashboardContentorTasks from "screens/Dashboard/Contentor/ContentTasks";
import DashboardContentor from "screens/Dashboard/Contentor";
import LegalPrivacity from "screens/Legal/Privacity";
import LegalAbout from "screens/Legal/About";
import LegalHelp from "screens/Legal/Help";
import LegalTerms from "screens/Legal/Terms";
import DashboardContentStudents from "screens/Dashboard/Contentor/ContentStudents";

export default function AppRouter() {
    return (
      <Router>  
        <div>
          <Switch>
            <Route path="/" exact> <Landpage /> </Route> 
            <Route path="/c3" exact> <C3 /> </Route> 
            
            <Route path="/privacity" exact> <LegalPrivacity /> </Route> 
            <Route path="/terms" exact> <LegalTerms /> </Route> 
            <Route path="/about" exact> <LegalAbout /> </Route> 
            <Route path="/help" exact> <LegalHelp /> </Route> 
            
            <Route path="/login" exact> <Login /> </Route> 
            <Route path="/register" exact> <Register /> </Route> 
            <Route path="/forgot" exact> <Forgot /> </Route> 
            <Route path="/create-password" exact> <CreatePassword /> </Route> 
            
            {/* <Route path="/dashboard" exact> <DashboardHome /> </Route>  */}
            
            <Route path="/dashboard" exact> <DashboardCreator /> </Route> 
            <Route path="/dashboard/creator" exact> <DashboardCreator /> </Route> 
            <Route path="/dashboard/creator/tasks" exact> <DashboardCreatorTasks /> </Route> 
            <Route path="/dashboard/creator/lessons" exact> <DashboardCreatorLessons /> </Route> 
            <Route path="/dashboard/creator/students" exact> <DashboardCreatorStudents /> </Route> 
            <Route path="/dashboard/creator/classes" exact> <DashboardCreatorClasses /> </Route> 
            
            <Route path="/dashboard/creator/game/association" exact> <DashboardCreatorTasksAssociation /> </Route> 
            <Route path="/dashboard/creator/game/content" exact> <DashboardCreatorTaskContent /> </Route> 
            <Route path="/dashboard/creator/game/contentvideo" exact> <DashboardCreatorTaskContentVideo /> </Route> 
            <Route path="/dashboard/creator/game/crossword" exact> <DashboardCreatorTasksCrossWord /> </Route> 
            <Route path="/dashboard/creator/game/gibbet" exact> <DashboardCreatorTasksGibbet /> </Route> 
            <Route path="/dashboard/creator/game/memory" exact> <DashboardCreatorTasksMemory /> </Route> 
            <Route path="/dashboard/creator/game/multichoice" exact> <DashboardCreatorGameMultiChoice /> </Route> 
            <Route path="/dashboard/creator/game/rightwrong" exact> <DashboardCreatorTasksRightWrong /> </Route> 


            <Route path="/dashboard/creator" exact> <DashboardCreator /> </Route> 
            <Route path="/dashboard/creator/tasks" exact> <DashboardCreatorTasks /> </Route> 
            <Route path="/dashboard/creator/lessons" exact> <DashboardCreatorLessons /> </Route> 
            <Route path="/dashboard/creator/lessons/:id" exact> <DashboardCreatorLessons /> </Route> 
            <Route path="/dashboard/creator/students" exact> <DashboardCreatorStudents /> </Route> 
            <Route path="/dashboard/creator/students/:id" exact> <DashboardCreatorStudents /> </Route> 
            <Route path="/dashboard/creator/classes" exact> <DashboardCreatorClasses /> </Route> 
            <Route path="/dashboard/creator/classes/:id" exact> <DashboardCreatorClasses /> </Route> 
            
            <Route path="/dashboard/creator/game/association/:id" exact> <DashboardCreatorTasksAssociation /> </Route> 
            <Route path="/dashboard/creator/game/content/:id" exact> <DashboardCreatorTaskContent /> </Route> 
            <Route path="/dashboard/creator/game/contentvideo/:id" exact> <DashboardCreatorTaskContentVideo /> </Route> 
            <Route path="/dashboard/creator/game/crossword/:id" exact> <DashboardCreatorTasksCrossWord /> </Route> 
            <Route path="/dashboard/creator/game/gibbet/:id" exact> <DashboardCreatorTasksGibbet /> </Route> 
            <Route path="/dashboard/creator/game/memory/:id" exact> <DashboardCreatorTasksMemory /> </Route> 
            <Route path="/dashboard/creator/game/multichoice/:id" exact> <DashboardCreatorGameMultiChoice /> </Route> 
            <Route path="/dashboard/creator/game/rightwrong/:id" exact> <DashboardCreatorTasksRightWrong /> </Route> 
            

            <Route path="/dashboard/contentor" exact> <DashboardContentor /> </Route> 
            <Route path="/dashboard/contentor/tasks" exact> <DashboardContentorTasks /> </Route> 
            <Route path="/dashboard/contentor/students" exact> <DashboardContentStudents /> </Route> 
            {/* <Route path="/dashboard/contentor/lessons" exact> <DashboardCreatorLessons /> </Route> 
            <Route path="/dashboard/contentor/classes" exact> <DashboardCreatorClasses /> </Route>  */}

            
            <Route path="/dashboard/plan" exact> <DashboardPlan /> </Route> 
            <Route path="/dashboard/me" exact> <DashboardMe /> </Route> 
            
            <Route path="*" exact> <NotFound /> </Route>
          </Switch>
        </div>
      </Router>
    );
}