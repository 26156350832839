import styled,  { keyframes } from 'styled-components' 

const scrollAnimation = keyframes`
  0% { transform: translateY(100%); }
  100% { transform: translateY(-100%); }
`;


export const Content = styled.div.attrs({ 
})`          
    overflow:hidden;
`;
 

export const LandPrereleaseCover = styled.div.attrs({ 
})`
    position: fixed;
    inset: 0 0 0 0;

    background: ${props => props.theme.palette.colors.backgrounddark }; 
    z-index: 10000;

`;

export const InfoContent = styled.div.attrs({ 
})`
    height: 100vh;
    width: 100vw;
    overflow: auto;
    padding: 0 10vw ;
`;

export const InfoContentIn = styled.div.attrs({ 
})`
    max-width: 980px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 15vh 0 1vh;
    margin: 0 auto;



    
`;


export const TigerDecorationImage = styled.img.attrs({ 
    src: '/images/mascot.png',
    width: 200
})`
    margin-bottom: 48px;
    margin-right: calc( 7% - 50px );
    cursor: pointer;
    transition: all .3s ease;
    &:hover{
        transform: scale( 1.05 );
    }
`; 


export const Title = styled.div.attrs({ 
})`          
    color: ${props => props.theme.palette.colors.white }; 
    text-align: center;
    font-size: 72px;
    line-height: 84px;
    font-weight: 900; 
    margin-bottom: 10vh;
    padding-bottom: 5vh;
    b {
        font-size: 100px;
    }
    @media(max-width: 600px){
        font-size: 32px;
        line-height: 44px;
        b {
            font-size: 48px;
        }
    }

    box-shadow: 0px 30px 30px  ${props => props.theme.palette.colors.shadow };
`;


export const AnimatedText = styled.div.attrs({ 
})`           
    // animation: ${scrollAnimation} 20s linear infinite;
`; 
 

export const AnimatedTextContainer = styled.div.attrs({ 
})`           
    overflow: hidden;
`; 
 

export const Text = styled.div.attrs({ 
})`           
    color: ${props => props.theme.palette.colors.white }; 
    font-size: 28px;
    margin-bottom: 48px;
    text-align: center;
`; 
 

export const UniIcon = styled.img.attrs({ 
    src:'/images/uni-logo.png',
    height: 80
})`
    margin: 6vh auto;
    cursor: pointer;
    transition: .3s all ease;
    &:hover{
        transform: scale( 1.2 ) ;
    }
`;



