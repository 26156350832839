import React from "react";  

import {  
    ContentBody,
    ContentAnimation
} from "./styled";

import { 
    Animation
} from "ui/styled";

import ContainerLandpage from "containers/Landpage";
import Button from "components/Form/Button";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export default function NotFound(){ 
 
    const history = useHistory();
    const navigate = to => history.push(`/${ to }`);

    return ( 
        <ContainerLandpage> 
            <ContentBody>
                <ContentAnimation>
                    <Animation animationData={require('assets/lotties/4004.json')}  />
                    <Button primary outline onClick={() => navigate("")} >
                        Ir para página inicial
                    </Button>
                </ContentAnimation>
            </ContentBody>  
        </ContainerLandpage>
    );
}