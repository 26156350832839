import styled from 'styled-components' 

export const FooterContainer = styled.div.attrs({ 
})`          
    background: ${ props => props.theme.palette.colors.backgrounddark }; 
    padding: 60px 0;
`;
    
export const FooterLogo = styled.img.attrs({ 
    src:'/logo1024.png'
})`          
    width: 184px;
    margin-bottom: 24px;
`;
   
export const FooterTitle = styled.div.attrs({  
})`          
    font-size: 20px;
    font-weight: 600;
    color: ${ props => props.theme.palette.colors.white };
    margin-bottom: 10px;
`;

   
export const FooterText = styled.div.attrs({  
})`          
    font-size: 20px;
    color: ${ props => props.theme.palette.colors.white };
`;
   
export const FooterLink = styled.div.attrs({  
})`          
    font-size: 20px;
    color: ${ props => props.theme.palette.colors.white };
    margin-bottom: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    &:hover {
        text-decoration: underline;
    }
`;

export const FooterLinkImage = styled.img.attrs({  
})`          
    height: 22px;
    width: 22px;
    margin-right: 16px;
`;
   
export const FooterSection = styled.div.attrs({  
})`           
    margin-top: 64px;
    @media(max-width:767px){
        display:flex;
        flex-direction: column;
        align-items: center;
    }
`;
    
export const FooterPhoneContent = styled.div.attrs({  
})`           
    display: flex;
    margin-top: 10px;
`;
    
export const FooterPhoneIcon = styled.img.attrs({  
    src:'/icons/phone.svg'
})`           
    width: 24px;
    margin-right: 20px;
`;
   
export const FooterCopyright = styled.div.attrs({  
})`          
    font-size: 20px;
    font-weight: 300;
    color: ${ props => props.theme.palette.colors.white };
    margin: 60px 0 0;
    text-align: center;
`;


export const ContainerAppLogo = styled.div.attrs({ 
})`          
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    margin: 140px 0 20px;

    cursor: pointer;
    transition: all .3s ease;
    &:hover{
        transform: scale(1.1);
    }
`;

export const AppLogo = styled.img.attrs({ 
    src:'/logo1024.png'
})`          
    width: 200px;
    margin: 0 auto;
`;



export const ImageButtonContainer = styled.div.attrs({ 
})`           
    padding: 120px 0 0px;

    @media(max-width: 767px){
        display: flex;
        flex-direction: column;
        align-items: center;
    }
`;

export const ImageButton = styled.img.attrs({ 
    width: 260
})`          
    display: block;
    cursor: pointer;
    transition: all .3s ease;
    margin: 10px 0;
    &:hover{
        transform: scale( 1.05 );
    }
`;



export const SubFooterIcon = styled.img.attrs({ 
    src:'/images/uni-logo.png',
    height: 80
})`
    margin-top: -80px;
    cursor: pointer;
    transition: .3s all ease;
    &:hover{
        transform: scale( 1.2 ) ;
    }
`;

export const SubFooter = styled.div.attrs({ 
})`
    display: flex;
    justify-content: flex-end;
    padding: 0 24px 0 0;
    border-bottom: 16px solid ${ p => p.theme.palette.colors.backgroundcontrast };
`;