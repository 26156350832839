import React, { useState } from 'react'

import { Row, Col, Container } from 'reactstrap'; 
import { useHistory } from 'react-router-dom';
 
import { 
    HeaderContainer,
    RowCenter,
    RowEnd,
    AppLogo,
    HeaderLeftMenu,
    HeaderMenuItem,
    HeaderMobile,
    MenuIcon,
    HeaderMobileMenu,
    HeaderMobileItem,
    ContainerAppLogo,
    HeaderRightMenu,
    HeaderMenu,
    HeaderMenuMenu,
    CloseIcon,
    HeaderMenuMenuSpacer,
    HeaderMenuMenuIcon
} from './styled';

import { 
    Touch,
    ButtonWhite
} from 'ui/styled';
import Button from 'components/Form/Button';
import { goSection, scrollTop } from 'utils';

export default function Header({  land }){
    
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
    const history = useHistory();

    const navigate = to => history.push(`/${ to }`);

    const options = land ? {
        left:[
            { title:'Ambiente Gamificado', action:() =>  goSection("section-gamification") },
            { title:'Economia de Tempo', action:() =>  goSection("section-economy") },
        ],
        right:[ 
            { title:'Assine Já', action:() => goSection('sign-now') },
            { title:'Entrar', action:() => navigate('login') },
        ]
    } : {
        left:[
            { title:'Início', action:() =>  navigate("") },
        ],
        right:[ ]
    }
    
    const optionsFooter = [
        // { title:'Inicio', action:() => scrollTop() },
        // { title:'Sobre', action:() => navigate('about') },
        { title:'Termos de uso', action:() => navigate('terms') },
        { title:'Políticas de privacidade', action:() => navigate('privacity') },
        { title:'Perguntas frequentes', action:() => navigate('faq') },
        { title:'Contato', action:() => navigate('contact') },
        { title:'Ajuda', action:() => navigate('help') },
    ]

    return (
        <>
            <HeaderContainer  land={ land }>
                <Container fluid>
                    <Row>
                        <Col md={{ size:5 }}> 
                            <RowCenter>
                                <HeaderLeftMenu>
                                    {
                                        options?.left?.map((item, key) => item.button ? 
                                            <Button key={key} nospace outline={item.outline} onClick={item.action}>{ item.title }</Button> 
                                                :
                                            <HeaderMenuItem key={key} active={item.active} onClick={item.action}>{ item.title }</HeaderMenuItem>
                                        )
                                    } 
                                </HeaderLeftMenu>
                            </RowCenter> 
                        </Col>
                        <Col md={{ size:2 }}>    
                            <ContainerAppLogo>
                                <AppLogo />
                            </ContainerAppLogo>
                        </Col>
                        <Col md={{ size:5 }}>
                            <HeaderRightMenu>
                                <RowEnd>
                                    {
                                        options?.right?.map((item, key) => item.button ? 
                                            <Button key={key} nospace outline={item.outline} onClick={item.action}>{ item.title }</Button> 
                                                :
                                            <HeaderMenuItem key={key} className={item.active ? "active" : ""} onClick={item.action}>{ item.title }</HeaderMenuItem>
                                        )
                                    } 

                                    <HeaderMenu spacer onClick={() => setMobileMenuOpen(!mobileMenuOpen)}>
                                        <MenuIcon /> 
                                    </HeaderMenu>
                                </RowEnd>

                                <HeaderMobile  onClick={() => setMobileMenuOpen(!mobileMenuOpen)} >
                                    <MenuIcon /> 
                                </HeaderMobile>

                            </HeaderRightMenu>
                        </Col>
                    </Row>
                </Container>
            </HeaderContainer>


            {
                mobileMenuOpen ? 
                    <HeaderMobileMenu>
                        <HeaderMenu onClick={() => setMobileMenuOpen(!mobileMenuOpen)} >
                            <CloseIcon /> 
                        </HeaderMenu>
                        <HeaderMenuMenu>
                            {
                                [...options?.left, ...options?.right]?.reverse()?.map((item, key) => item.button ? 
                                    <HeaderMobileItem key={key} centred> 
                                        <Button nospace outline={item.outline} nomargin onClick={item.action}>{ item.title }</Button> 
                                    </HeaderMobileItem>
                                        :
                                    <HeaderMobileItem key={key} active={item.active} onClick={item.action}>{ item.title }</HeaderMobileItem>
                                )
                            }  

                            <HeaderMenuMenuSpacer />

                            {
                                [...optionsFooter]?.slice(0,2)?.map((item, key) => item.button ? 
                                    <HeaderMobileItem key={key} centred> 
                                        <Button nospace outline={item.outline} nomargin onClick={item.action}>{ item.title }</Button> 
                                    </HeaderMobileItem>
                                        :
                                    <HeaderMobileItem key={key} active={item.active} onClick={item.action}>{ item.title }</HeaderMobileItem>
                                )
                            }  

                            <HeaderMenuMenuIcon onClick={scrollTop} />
                        </HeaderMenuMenu>
                    </HeaderMobileMenu>
                : null
            }
        </>
    )
}