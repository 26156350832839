import React from "react";  

import {  
    CardHome,
    CardHomeTitle,
    DashboardTitle
} from "./styled";

import ContainerAuthenticated from "containers/Authenticated";
import { Row, Col } from "reactstrap";

import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export default function DashboardCreatorTasksMemory(){  

    const history = useHistory();
    const navigate = to => history.push(`/${ to }`);

    const options_content = [
        { title:"Conteúdo (Texto) ", action: () => navigate("dashboard/creator/game/content-text") },
        { title:"Conteúdo (Vídeo) ", action: () => navigate("dashboard/creator/game/content-video") }, 
    ]

    const options = [ 

        { title:"Certo . Errado", action: () => navigate("dashboard/creator/game/boolean") },
        { title:"Multipla Escolha", action: () => navigate("dashboard/creator/game/right-wrong") },
        { title:"Associação", action: () => navigate("dashboard/creator/game/association") },
        { title:"Jogo da memória", action: () => navigate("dashboard/creator/game/memory") },
        
        { title:"Jogo da forca", action: () => navigate("dashboard/creator/game/gibbet") },
        { title:"Palavras cruzadas", action: () => navigate("dashboard/creator/game/cross-words") },
    ]

    return ( 
        <>
            <ContainerAuthenticated> 

                <DashboardTitle>Jogo da memória</DashboardTitle>
                
    
            </ContainerAuthenticated> 
        </>
    );
}