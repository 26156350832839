import React, { useState } from "react"; 

import { Row, Col, Container } from 'reactstrap'; 
 
import ToggleTab from 'components/Landpage/Toggle/Tab'
import HowWorksItem from './Item'

import {
    HowWorksContainer, 
    BannerText,
    BannerTitle,
    BannerButton
} from './styled'
import Button from "components/Form/Button";
import { goSection } from "utils";

export default function HowWorks(){ 
    return ( 
        <> 
            <HowWorksContainer> 
                <Container>
                    <Row> 
                        <Col>
                            <BannerTitle>Gamificação torna o aprendizado mais divertido</BannerTitle>
                            <BannerText>Não perca a oportunidade de revolucionar sua sala de aula</BannerText>
                            <BannerButton>
                                <Button outline white  onClick={() => goSection("sign-now")}>Assine já</Button>
                            </BannerButton>
                        </Col>
                    </Row>
                </Container>
            </HowWorksContainer>
        </>
    );
}