import React, { useEffect, useRef, useState } from "react";  

import {  
    ContentBody,
    ContentAnimation,
    IframeGame,
    GameLoading,
    GameArea,
    GameImageLoading
} from "./styled";

import { 
    Animation
} from "ui/styled";

import ContainerLandpage from "containers/Landpage";
import Button from "components/Form/Button";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export default function C3(){ 
 
    const history = useHistory();
    const navigate = to => history.push(`/${ to }`);

    const [closed, setClosed] = useState(false)

    const init = () => {
        setTimeout(() => {
            setClosed(true)
            playSound()
        }, 5800 )
    }


    let audioContext = useRef()?.current
    let source = useRef()?.current

    const playSound = async ()  => {
        if (!audioContext) {
            audioContext = new (window.AudioContext || window.webkitAudioContext)();
        }

        const response = await fetch('/sounds/game.mp3');
        const arrayBuffer = await response.arrayBuffer();
        const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);

        source = audioContext.createBufferSource();
        source.buffer = audioBuffer;
        source.loop = true;
        source.connect(audioContext.destination);
        source.start();
    }

    const stopSound = () => {
        if (source) {
            source.stop();
            source.disconnect();
            source = null;
        }
    }

    useEffect(() => {
        init()
        return () => {
            stopSound()
        }
    }, [])

    return ( 
        <ContainerLandpage> 
            <ContentBody>

                <GameArea>
                    <IframeGame />
                    <GameLoading close={closed}>
                        <GameImageLoading />
                    </GameLoading>
                </GameArea>

            </ContentBody>  
        </ContainerLandpage>
    );
}