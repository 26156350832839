import styled from 'styled-components'  

export const ContentInput = styled.div.attrs({ 
})`            
    display: flex;
    flex-direction: column;
    gap: 8px;
`;



export const ContentUpload = styled.div.attrs({ 
})`            
    padding: 24px;
    border: 3px dashed ${ props => props.theme.palette.colors.backgrounddark };

    aspect-ratio: 3 / 2 ;

    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;

    background: ${
        p => p.image ? `
            url(${p.image}) no-repeat center center / cover;
        ` : ``
    }

    cursor: pointer;
    transition: all .3s ease;
    &:hover{
        transform: scale(1.01);
    }
`;


export const ContentUploadText = styled.div.attrs({ 
})`            
    font-size: 16px;
    font-weight: 400;
    text-align: center;
`;

export const ContentUploadPlaceholder = styled.div.attrs({ 
})`            
    font-size: 16px;
    font-weight: 400;
`;
