import React from "react";  

import {  
    CardFormActions,
    CardFormButton,
    CardHome,
    CardHomeTitle,
    DashboardTitle
} from "./styled";

import ContainerAuthenticated from "containers/Authenticated";
import { Row, Col } from "reactstrap";

import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Input from "components/Form/Input";
import useController from "./controller";
import CustomButton from "components/Form/CustomButton";

export default function DashboardCreatorStudents(){  

    const history = useHistory();
    const navigate = to => history.push(`/${ to }`);

    const { 
        changeForm,
        formValue,
        loading,
        saving,
        saveForm
    } = useController()

    const options = [
        // { title:"Certo . Errado", action: () => navigate("dashboard/creator/tasks") },
        // { title:"Multipla Escolha", action: () => navigate("dashboard/creator/tasks") },
        // { title:"Associação", action: () => navigate("dashboard/creator/tasks") },
        // { title:"Jogo da memória", action: () => navigate("dashboard/creator/tasks") },
        
        // { title:"Conteúdo ", action: () => navigate("dashboard/creator/tasks") },
    ]

    return ( 
        <>
            <ContainerAuthenticated> 

                <Row>
                    <Col sm={12} md={6} lg={3}> 
                        <DashboardTitle>Criar aluno</DashboardTitle>
                    </Col>
                </Row>
                <Row>
                    <Input type="textarea" placeholder="Nome do aluno" value={formValue("name")} onChange={e => changeForm(e.target.value, "name")} />
                    <CardFormActions>
                        <CardFormButton>
                            <CustomButton loading={saving} onClick={saveForm}>Salvar</CustomButton>
                        </CardFormButton>
                    </CardFormActions>
                </Row>
    
            </ContainerAuthenticated> 
        </>
    );
}