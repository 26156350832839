import styled from 'styled-components' 

import { hexToRgb } from 'ui/styled'

export const MoreContainer = styled.div.attrs({ 
})`          
    position: relative;
    display: flex;
    flex-direction: column;
`;
  
export const MoreRow = styled.div.attrs({ 
})`           
`;
 
export const MoreTitle = styled.div.attrs({ 
})`          
    font-size: 32px;
    font-weight: bold;
    color: ${ props => props.theme.palette.colors.white };
`;
 
export const MoreLine = styled.div.attrs({ 
})`          
    margin: 10px 0 20px;
    height: 2px;
    border-radius: 1px;
    background: ${ props => props.theme.palette.colors.white };
    margin-right: 150px;
`;
 
export const MoreText = styled.div.attrs({ 
})` 
    font-size: 16px; 
    color: ${ props => props.theme.palette.colors.white };
`;
 
export const MoreLogoCircle = styled.div.attrs({ 
})` 
    max-width: 398px;
    width: 100%;
    min-height: 398px;
    max-height: 398px;
    border-radius: 50%;
    background:  ${ props => props.theme.palette.secondary.main };
    box-shadow: 0px 14px 12px ${ props => props.theme.palette.colors.shadow };
    display: flex;
    align-items: center;
    justify-content: center;
    margin: -50px 0 -50px auto;
    overflow: hidden;
`;
 
 
export const MoreLogo = styled.img.attrs({ 
    src:'/logo1024.png'
})` 
    width: 400px;
`;
 
export const MoreDecoration = styled.img.attrs({ 
    src:'/icons/circle-2.svg'
})` 
    width: 793px;
    position: absolute;
    bottom: 10px;
    right: -320px;
    z-index: -1;
`;
 
export const BannerTitle = styled.div.attrs({ 
})`           
    margin: 15vh 0 30px;
    color: ${props => props.theme.palette.colors.black }; 
    text-align: center;
    font-size: 72px;
    line-height: 84px;
    font-weight: 900;
    @media(max-width: 600px){
        font-size: 32px;
        line-height: 44px;
    }
`; 


export const BannerText = styled.div.attrs({ 
})`           
    color: ${props => props.theme.palette.colors.black }; 
    text-align: center;
    font-size: 25px;
    font-weight: 700;
`; 





export const MoreRegister = styled.div.attrs({ 
})`           
    position: relative;
    z-index:2;
    background: ${props => props.theme.palette.colors.white };
    // border: .5px solid ${props => `rgba(${hexToRgb(props.theme.palette.colors.backgrounddark)},.1)` };
    // box-shadow: 0px 1px 3px ${props => props.theme.palette.colors.lightshadow };
    margin-top: -10vh;
`; 

export const RoyalVideoContainer = styled.div.attrs({ 
})`
    display: flex;
    flex-direction: column;
    margin-bottom: -27vh;
    position: relative;
    z-index:3;
`; 

export const RoyalVideo = styled.iframe.attrs({ 
    width:"100%",
    src:"https://www.youtube.com/embed/EDtDHMVvJSg?ref=0&loop=1&autoplay=1&mute=1&playlist=EDtDHMVvJSg",
    title:"YouTube video player",
    frameborder:"0",
    allow:"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share",
    referrerpolicy:"strict-origin-when-cross-origin",
    allowfullscreen:true,
})`       
    display: block;
    margin: 10vh auto 0;
    aspect-ratio: 16 / 9 ;    
    @media( max-width: 991px ){
        display: none;
    }
`;

export const RoyalVideoMobile = styled.iframe.attrs({ 
    width:"100%",
    src:"https://www.youtube.com/embed/E8AWVe5Xepc?ref=0&loop=1&autoplay=1&mute=1&playlist=E8AWVe5Xepc",
    title:"YouTube video player",
    frameborder:"0",
    allow:"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share",
    referrerpolicy:"strict-origin-when-cross-origin",
    allowfullscreen:true,
})`       
    display: none;
    margin: 10vh auto 0;
    aspect-ratio: 9 / 16 ;
    @media( max-width: 991px ){
        display: block;
    }
`; 
