import React from "react";  

import {  
    DashboardAnimation,
    DashboardTitle,
    DashboardText,
    CardHome,
    CardHomeTitle
} from "./styled";

import ContainerAuthenticated from "containers/Authenticated";
import { Row, Col } from "reactstrap";
import { ChartBar } from "components/Dashboard/Charts/Bar";
import { ChartCalendar } from "components/Dashboard/Charts/Calendar";
import { ChartLine } from "components/Dashboard/Charts/Line";
import { ChartPie } from "components/Dashboard/Charts/Pie";
import { ChartTimeline } from "components/Dashboard/Charts/Timeline";
import { ChartWordtree } from "components/Dashboard/Charts/Wordtree";

export default function DashboardPlan(){  

    return ( 
        <>
            <ContainerAuthenticated> 

                <Row>
                    <Col sm={12} md={6} lg={3}> 
                        <CardHome>
                            <CardHomeTitle>Meu plano</CardHomeTitle>
                        </CardHome>
                    </Col> 
                    <Col sm={12} md={6} lg={9}> 

                    </Col>  
                </Row> 

            </ContainerAuthenticated> 
        </>
    );
}