import React from "react";  

import {  
    CardHome,
    CardHomeTitle,
    ContentRight,
    ContentRightItem,
    ContentRightTitle,
    DashboardTitle,
    CardOption, 
    CardOptionResponse, 
    CardOptionTitle, 
    CardOptionsWrap, 
    CardOptionsWrapIcon, 
    DashboardSideMascot, 
    DashboardSideMascotBaloon, 
    SimpleRow,
    CardForm,
    CardFormButton,
    CardOptionDecoration,
    CardOptionDecorationIcon,
    CardFormBetweeRow
} from "./styled";

import ContainerAuthenticated from "containers/Authenticated";
import { Row, Col } from "reactstrap";

import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Input from "components/Form/Input";
import useController from "./controller";
import Toggle from "components/Form/Toggle";
import { FormAction, FormActions, FormContainer, GeneratedAction, Load, LoadCenter, ManualAction } from "ui/styled";
import Button from "components/Form/Button";
import ImagedInput from "components/Form/ImagedInput";
import {  } from "components/Dashboard/Side/styled";
import CustomButton from "components/Form/CustomButton";
import DashboardCardRightWrong from "screens/Dashboard/Cards/RightWrong";
import CreatorMascotIA from "components/Creator/MascotIA";
import CreatorBaloonIA from "components/Creator/BaloonIA";
import DashboardCardContent from "screens/Dashboard/Cards/Content";

export default function DashboardCreatorTaskContent({ embeded }){  

    const history = useHistory();
    const navigate = to => history.push(`/${ to }`);

    const {

        saving,
        loading, 
        thinking,

        formValue,
        changeForm,

        saveForm,
        saveMany,

        askAI,
        generated,

        toggleSelected,
        isSelected,
        selected,
        
        updateSuggestion,
        toggleManual,
        manual,
        editing,

        ctaIA

    } = useController()


    return ( 
        <>
            <ContainerAuthenticated flat={!!embeded}> 

                {
                    loading ? <LoadCenter>
                        <Load colored />
                    </LoadCenter> : <>

                        {
                            manual ? null : <>

                                <Row>
                                    <Col md={1} sm={12}></Col>
                                    <Col md={9} sm={12}>
                                        <CardOptionsWrapIcon loading={thinking} /> 
                                        <DashboardTitle id="form">Conteúdo</DashboardTitle>

                                        <CreatorBaloonIA generated={generated} />

                                        <CardForm>
                                            <Input id={"input-ai"} placeholder="Qual o tema da aula?" value={formValue("theme")} onChange={e => changeForm(e.target.value, "theme")} onSubmitEditing={askAI} />
                                            <CardFormButton>
                                                <CustomButton loading={thinking} onClick={askAI}>Gerar</CustomButton>
                                            </CardFormButton>
                                        </CardForm> 

                                        <CardOptionsWrap>
                                            {
                                                !generated?.length ? null : generated?.map((item, key) => 
                                                    <DashboardCardContent 
                                                        key={key} 
                                                        toggleSelected={toggleSelected} 
                                                        isSelected={isSelected} 
                                                        updateSuggestion={updateSuggestion} 
                                                        item={item} 
                                                    />
                                                )
                                            }
                                        </CardOptionsWrap>

                                        {
                                            !selected?.length ? null : <>
                                                <FormActions>
                                                    <FormAction>
                                                        <CustomButton loading={saving} onClick={saveMany}>Salvar Selecionados</CustomButton>
                                                    </FormAction>
                                                </FormActions>
                                            </>
                                        }

                                    </Col>
                                    <Col md={2} sm={12}></Col>
                                </Row>
                            </>
                        }



                        {
                            generated?.length ? null : <>
                                <Row>
                                    <Col md={1} sm={12}></Col>
                                    <Col md={9} sm={12}>
                                            {
                                                !manual ?  <>
                                                    <ManualAction>
                                                        <Button primary link onClick={toggleManual}>Criar manualmente</Button>
                                                    </ManualAction>
                                                </> : <>
                                                    <DashboardTitle id="form">Conteúdo</DashboardTitle>
                                                    <FormContainer>
                                                        <ImagedInput placeholder="Conteúdo" value={formValue("question")} onChange={e => changeForm(e.target.value, "question")} />
                                                        {/* <Input type="textarea" placeholder="Feedback" value={formValue("feedback")} onChange={e => changeForm(e.target.value, "feedback")} />         */}

                                                        <CardFormBetweeRow>
                                                            <FormAction>
                                                                <CustomButton loading={saving} onClick={saveForm}>Salvar</CustomButton> 
                                                            </FormAction>
                                                        </CardFormBetweeRow>
                                                    </FormContainer>
                                                    {
                                                        editing ? null : 
                                                            <ManualAction>
                                                                <Button primary link onClick={toggleManual}>Gerar com IA</Button>
                                                            </ManualAction>
                                                    }
                                                </>
                                            }

                                    </Col>
                                    <Col md={2} sm={12}></Col>
                                </Row>
                            </>
                        }
                    </>
                }
                <CreatorMascotIA editing={editing} generated={generated} onClick={ctaIA} />
            </ContainerAuthenticated> 
        </>
    );
}