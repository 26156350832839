import styled from 'styled-components'  

export const DashboardSideMascot = styled.div.attrs({ 
})`
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100px;
    height: 100px;
    background: url(/images/pets/white.png) no-repeat center bottom / contain;
    cursor: pointer;
    transition: all .3s ease;
    
    ${
        p => p.generated ? `
            div {
                display: none;
            }
            &:hover div{
                display: block;
            }
        ` : ``
    }
    
    & :hover {
        transform: scale(1.05);
    }
`;

export const DashboardSideMascotBaloon = styled.div.attrs({ 
})`
    position: absolute;
    right: 100px;
    top: -40px; 
    border-top-left-radius: 20px; 
    border-top-right-radius: 20px; 
    border-bottom-left-radius: 20px; 
    padding: 12px;
    background: ${ props => props.theme.palette.colors.white };
    border: 1px solid ${ props => props.theme.palette.colors.backgrounddark };
    text-align: center;
`;