import React, { useContext, useState } from "react"; 
import { useHistory } from 'react-router-dom'; 
import { toast } from 'react-toastify';


import { 
    FormTitle,
    FormText,
    FormSpacer,
    RegisterCall,
    RegisterButton,
    FormSubtitle,
    FormSteper,
    FormSteperItem,
    FormSteperButton,
    RowForm,
    FormSupertext,
    FormSteperCheck,
    FormSteperDecoration,
    FormSteperDecorationLine,
    FormSteperDecorationContent,
    AnimateChilds,
    FormSteperSuccess
} from './styled'

import Button from "components/Form/Button";
import Input, { MaskedInput } from 'components/Form/Input';

import ContainerUnauthenticated from "containers/Unauthenticated";
import { DoLogin, DoRegister } from "services/authentication";
import { exposeStrapiError, goSection } from "utils"; 
import PasswordValidation from "components/Form/PasswordValidation";
import { Load } from "components/Form/Button/styled";
import { isEmail, isPassword } from "utils/validators";
import { CoreContext } from "context/CoreContext";
import { CreateCard, CreateSubscription } from "services/pagarme";
import { ReadActivePlan, Read as ReadPlans } from "services/plans";
import { getCardBrand } from "utils/brazil";
import CustomButton from "components/Form/CustomButton";

export default function Register({ flat }){ 

    const history = useHistory();
    const navigate = to => history.push(`/${ to }`);

    const { setUser } = useContext(CoreContext)

    const [ loading, setLoading ] = useState(false) 
    const [ error, setError ] = useState(null) 
    
    const steps = [
        { title:"Dados pessoais", label:"Continuar" },
        { title:"Dados de acesso", label:"Continuar" },
        { title:"Sobre o plano", label:"Assinar plano mensal no valor de R$ 29,90" },
        { title:"Sucesso", label:"Acessar plataforma" },
    ]

    const benefits = [
        { title:"Desconto especial de lançamento." },
        { title:"Mensalidade de R$ 29,90 durante o primeiro ano." },
        { title:"Sem compromisso, cancele quando quiser." },
        // { title:"Tecnologia inovadora, por um preço acessível." },
        { title:"Centenas de avatares para customização dos alunos." },
        { title:"Cadastre até 400 alunos." },
        { title:"Tarefas e aulas ilimitadas." },
        { title:"Multi-plataforma no celular ou online." },
    ]

    const [ step, setStep ] = useState(0) 
    
    const [ form, setForm ] = useState({})
    const formValue = ref => { return form?.[ref] ? form?.[ref] : '' ;}
    const changeForm = ( value, ref ) => { setForm({ ...form, [ref]: value }) ;} 

    const valid = (verbose = false) => {  

        if(step === 0){
            if(!formValue('name') || !formValue('name').length){ 
                if(verbose){ toast.error('Informe seu nome') ;}
                return false; 
            }  
    
            if(!formValue('email') || !formValue('email').length){ 
                if(verbose){ toast.error('Informe seu e-mail') ;}
                return false; 
            }  
    
            if(! isEmail(formValue('email')) ){ 
                if(verbose){ toast.error('O e-mail informado é inválido') ;}
                return false; 
            }  
        }

        if(step === 1){
            if(!formValue('password') || !formValue('password').length){ 
                if(verbose){ toast.error('Informe sua senha') ;}
                return false; 
            }
            if(! isPassword(formValue('password')) ){ 
                if(verbose){ toast.error('A senha informada não atende os requisitos') ;}
                return false; 
            }
        }  

        return true
    } 

    const action = async () => {
        setLoading(true)
        
        const result = await DoRegister({
            ...form, 
            username: form.email?.replace(/ /g,''),
            email: form.email?.replace(/ /g,''),
            confirmed:true,
            blocked:false,
            role:1
        })  
        
        setLoading(false)
        if(result && !exposeStrapiError(result)){
            completeLogin()
        } 
    }

    const completeLogin = async () => {
        setLoading(true)
        toast.success('Conta criada com sucesso'); 
        const result = await DoLogin({ password: form?.password, identifier: form.email?.replace(/ /g,'') })  
        if(result && !exposeStrapiError(result)){
            recompleteLogin(result)
        } 
        setLoading(false)
    } 

    const recompleteLogin = (result) => {
        if(result?.user){ setUser(result.user) }
        setStep( step + 1)
    }  

    const buyPlay = async () => {
        setLoading(true)
        const plans = await ReadActivePlan()
        const planId = plans?.data?.[0]?.id

        if(!planId){
            toast.error("Plano não encontrado, tente novamente mais tarde")
            setLoading(false)
            return ;
        }

        const createdCard = await CreateCard({
            "number": form?.card_number?.replace(/\ /g, ''),
            "holder_name": form?.card_holder,
            "exp_month": parseInt(form?.card_expiresAt?.split('/')?.[0]),
            "exp_year": parseInt(form?.card_expiresAt?.split('/')?.[1]),
            "cvv": form?.cvv,
            "brand": getCardBrand( form?.card_number?.replace(/\ /g, '') )
        })

        if(!createdCard?.id){
            toast.error("Erro ao criar o cartão verifique os dados informados e tente novamente")
            setLoading(false)
            return ;
        }

        const result = await CreateSubscription({ "planId": planId, "cardId": createdCard?.id })

        if(result && !exposeStrapiError(result)){
            toast.success("Plano assinado com sucesso!")
            setStep( step + 1 )
        } else {
            setError({
                title:"Erro ao realizado o pagamento, verifique os dados informados e tente novamente",
                description: "Não autorizado pelo operadora code (00101), favor consultar sua operadora de cartão",
                code: "A-0123876"
            }) 
        }
 
        setLoading(false)
    }

    const next = () => {
        if(!valid(true)){ return ;}
        goSection("form-sign")
        setTimeout(() => {    
            if(step === 0) { 
                setStep( step + 1 )
                return;
            }
            if(step === 1) { 
                action()
                return;
            }
            if(step === 2) { 

                buyPlay()
                return;
            }
            if(step === 3) { 
                navigate("login")
                return;
            }
        }, 150)
    }
 
    return ( 
        <>  
            <ContainerUnauthenticated flat={flat}> 

                <AnimateChilds>

                        <FormTitle>Cadastre-se agora</FormTitle>
                        <FormText> e aproveite todas as vantagens da nossa plataforma </FormText> 
                        <FormSubtitle>| Transforme suas aulas com a Royal Academy!</FormSubtitle>
                        <div id="form-sign" />
                        <FormSteper>
                            {
                                steps.map((item, key) => 
                                    <FormSteperItem key={key} done={step > key} active={step === key} onClick={step > key ? () => setStep(key) : null} />
                                )
                            }
                        </FormSteper>

                        <FormText>| { steps?.[step]?.title }</FormText>
                        {
                            step === 0 ? <>
                                <Input placeholder="Nome" id={'name'} value={formValue('name')} onChange={e => changeForm(e.target.value, 'name')} white /> 
                                <FormSpacer />
                                <Input placeholder="E-mail" id={'email'} value={formValue('email')} onChange={e => changeForm(e.target.value, 'email')} white /> 
                                <FormSpacer />
                            </> : null
                        }

                        {
                            step === 1 ? <>
                                <Input white placeholder="Senha" id={'password'} type="password" value={formValue('password')} onChange={e => changeForm(e.target.value, 'password')}  />    
                                <FormSpacer /> 
                                <PasswordValidation password={form?.password} />
                            </> : null
                        }


                        {
                            step === 2 ? <>
                                <FormSteperDecoration>
                                    <FormSteperDecorationContent>
                                        <FormTitle spaced>Ultimate</FormTitle>
                                        <FormSteperDecorationLine />
                                    </FormSteperDecorationContent>
                                </FormSteperDecoration>

                                <FormText> A assinatura continua automaticamente, <b onClick={() => navigate("terms")}>veja os termos</b>. </FormText> 

                                {
                                    benefits?.map((item, key) => 
                                        <FormSupertext key={key}>| <FormSteperCheck /> { item.title }</FormSupertext>
                                    )
                                }
                                <FormSpacer /> 


                                {
                                    !error ? null : <>
                                        <FormSteperSuccess error>
                                            <FormSteperCheck big error /> 
                                        </FormSteperSuccess>
                                        <FormSteperDecoration> 
                                            <FormTitle>| { error?.title }</FormTitle>
                                            <FormSupertext># { error?.code }</FormSupertext>
                                            <FormText big>{ error?.description }</FormText>
                                        </FormSteperDecoration>
                                    </>
                                }

                                <FormText>| Dados de pagamento</FormText>

                                <Input placeholder="Nome no cartão" id={'card_holder'} value={formValue('card_holder')} onChange={e => changeForm(e.target.value, 'card_holder')} white /> 
                                <FormSpacer />
                                <MaskedInput mask={"9999 9999 9999 9999"} placeholder="0000 0000 0000 0000" id={'card_number'} value={formValue('card_number')} onChange={e => changeForm(e.target.value, 'card_number')} white /> 
                                <FormSpacer />
                                <RowForm>
                                    <MaskedInput mask={"99/99"} placeholder="Data de validade" id={'card_expiresAt'} value={formValue('card_expiresAt')} onChange={e => changeForm(e.target.value, 'card_expiresAt')} white /> 
                                    <MaskedInput mask={"999"} placeholder="CVV" id={'card_cvv'} value={formValue('card_cvv')} onChange={e => changeForm(e.target.value, 'card_cvv')} white /> 
                                </RowForm>
                                <FormSpacer />
                                <MaskedInput mask={"999.999.999-99"} placeholder="CPF" id={'card_cpf'} value={formValue('card_cpf')} onChange={e => changeForm(e.target.value, 'card_cpf')} white /> 
                                <FormSpacer />
                            </> : null
                        }


                        {
                            step === 3 ? <> 
                                <FormSteperSuccess>
                                    <FormSteperCheck big /> 
                                </FormSteperSuccess>
                                <FormSteperDecoration spaced>
                                    <FormSteperDecorationContent>
                                        <FormTitle>Parabéns</FormTitle>
                                        <FormSteperDecorationLine />
                                    </FormSteperDecorationContent>
                                    <FormTitle spaced>sua assinatura foi confirmada</FormTitle>
                                    <FormText big>Bem vindo ao futuro! Agora você é um membro dessa revolução e faz parte do maior inovação tecnológica na história da educação</FormText>
                                </FormSteperDecoration>
                            </> : null
                        }

                        <RegisterButton centred={step === 3}  huge={step === 2}>
                            <CustomButton loading={loading} onClick={next}>
                                { steps?.[step]?.label }
                            </CustomButton>
                        </RegisterButton>

                        {/* 
                            <RegisterCall> Já possui uma conta? </RegisterCall>
                            <Button primary outline onClick={() => navigate('login')}>Faça o login</Button> 
                        */}
                </AnimateChilds>
                
            </ContainerUnauthenticated> 
        </>
    );
}