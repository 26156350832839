import styled from 'styled-components' 

export const Content = styled.div.attrs({ 
})`          
    overflow:hidden;
`;
 
export const ContentBody = styled.div.attrs({ 
})`          
    min-height: calc(100vh - 0px);
    display: flex;
    align-items: center;
    justify-content: center;
`;
  
export const ContentAnimation = styled.div.attrs({ 
})`          
    margin-top: -15vh;
    display: flex;
    flex-direction: column;
    gap: 10vh;
`;
 