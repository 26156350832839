import { CoreContext } from "context/CoreContext";
import moment from "moment/moment";
import React, { useContext, useEffect, useState } from "react";  

import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { GenerateQuestions } from "services/ai";
import { Create, ReadOne, Update } from "services/questions";
import { exposeStrapiError, goSection, normalizeStrapiRegister } from "utils";

export default function useController(){  

    const history = useHistory();
    const navigate = to => history.push(`/${ to }`);

    const params = useParams()

    const { user } = useContext(CoreContext)

    const [saving, setSaving] = useState(false)
    const [loading, setLoading] = useState(false)
    const [thinking, setThinking] = useState(false)
    
    const [manual, setManual] = useState(false)
    const [editing, setEditing] = useState(false)
    const [generated, setGenerated] = useState([])
    const [selected, setSelected] = useState([])
    
    const [seeds, setSeeds] = useState([])
    
    const [ form, setForm ] = useState({})
    const formValue = ref => { return form?.[ref] ? form?.[ref] : '' ;}
    const changeForm = ( value, ref ) => { setForm({ ...form, [ref]: value }) ;} 

    const askAI = async () => {
        if(!thinking){
            setThinking(true)

            const payload = {
                "theme": form?.theme,
                "type": "rightwrong",
                "quantity": 4
            }
    
            let result = await GenerateQuestions(payload)
    
            if(!result?.length){ result = await GenerateQuestions(payload) ;}
            if(!result?.length){ result = await GenerateQuestions(payload) ;}
            if(!result?.length){ result = await GenerateQuestions(payload) ;}
    
            if(!result?.length){
                setThinking(false)
                toast.error("Nossa IA encontrou dificuldades para gerar suas questões, tente novamente")
            }
            
            const momentH = moment().format("HHmm")

            if(typeof result?.map === 'function'){
                const dataResult = [
                    // ...generated,
                    ...result?.map( (m, k) => ({ ...m, id: `${momentH}${k}` }))
                ]

                setGenerated(dataResult)

                setSeeds([ 
                    ...seeds, {
                        seed: form?.theme,
                        result: dataResult
                    } 
                ])
            }

            setThinking(false)
        }
    }

    const ctaIA = () => {
        if(manual){
            toggleManual()
            return;
        }
        document.getElementById("input-ai").focus()
    }

    const acceptAISuggestion = item => {
        setForm({
            ...form, 
            ...item
        })
        toast.info("Revise a questão selecionada")
        goSection("form")
    }

    const isSelected = item => {
        return selected?.map(m => m.id)?.includes(item?.id)
    }

    const toggleSelected = item => {
        setSelected(
            isSelected(item) ? [
                ...selected?.filter(f => f.id !== item.id )
            ] : [ ...selected, { ...item } ]
        )
    }

    const updateSuggestion = item => {
        setGenerated([
            ...generated?.map(f => f.id !== item.id ? f : { ...f, ...item })
        ])
    }

    const toggleManual = () => {
        setManual(!manual)
    }

    const saveForm = async () => {
        await save(form)
    }

    const saveMany = async () => {
        setSaving(false)
        const promises = selected?.map(m => save(m, true))
        const res = await Promise.all(promises)
        if( res?.filter(f => !!f)?.length === promises?.length ){
            toast.success("Conteúdo salvo com sucesso")
            navigate("dashboard/contentor/tasks")
        }

        setSaving(true)
    }

    const save = async (ff, many) => {
        if(!ff?.question){ return ;}
        if(!many){ setSaving(true) ;}
        const payload = {
            type:"rightwrong",
            time: 60,
            image: ff?.question?.url ? ff?.question?.id : null,
            question: ff?.question?.url ? null : ff?.question,
            feedback: ff?.feedback,
            answer: ff?.answer, // boolean

            // reverse: true, // timer
            // small: true, // memory size
            // image:"", // ask is an image
            // options:[
            //     {
            //         answer:"",
            //         correct: true, // correct choice
            //         // image:"", response option is an image
            //         // key: "", // association key
            //         // associate: "", // association target
            //     }
            // ],
            
            order: 1,
            owner: user?.id,
            seed: form?.theme
        }

        const result = editing ? await Update({ data: payload }, params?.id) : await Create({ data: payload })

        if(result && !exposeStrapiError(result)){
            toast.success("Questão salva com sucesso")
            if(!many){ navigate("dashboard/contentor/tasks") ;}else{
                return true;
            }
        }
        if(!many){ setSaving(false) ;}
    }

    const init = async () => {
        setLoading(true)
            const result = await ReadOne(params?.id)
            const normalResult = normalizeStrapiRegister(result)
            
            const nextForm = {
                ...normalResult,
                question: normalResult?.image?.url ?  normalizeStrapiRegister(normalResult?.image) : normalResult?.question
            }

            console.log("nextForm", nextForm)

            setForm(nextForm)
            setManual(true)
            setEditing(true)
        setLoading(false)
    }

    useEffect(() => {
        if(params?.id){ init() ;}
    }, [params])

    return {
        saving,
        loading, 
        thinking,

        formValue,
        changeForm,
        
        save,
        saveMany,
        saveForm,

        askAI,
        generated,
        acceptAISuggestion,
        
        toggleSelected,
        isSelected,
        selected,

        updateSuggestion,
        
        toggleManual,
        manual,
        editing,

        ctaIA
    }
}