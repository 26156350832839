import styled from 'styled-components' 
import ReactLoading from 'react-loading';
import Lottie from 'react-lottie';

export const hexToRgb = (hex) => {
    var c;
    if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
        c= hex.substring(1).split('');
        if(c.length === 3){
            c= [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c= '0x'+c.join('');
        return [(c>>16)&255, (c>>8)&255, c&255].join(',') ;
    }
    return `255,255,255`
}  

export const Touch = styled.div.attrs({ 
})`         
    cursor: pointer;
    &:hover{
        box-shadow: 0px 1px 3px ${ props => props.theme.palette.colors.shadow };
    }
`;

export const Load = styled(ReactLoading).attrs( p => ({ 
    type:'spin',
    color: p.colored ? p.theme.palette.colors.backgrounddark : p.theme.palette.colors.white,
    height:20,
    width:20
}))`          
`;
 
export const Animation = styled(Lottie).attrs( props => ({  
    options:{
        loop: true,
        autoplay: true, 
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        },
        animationData: props.animationData
    }, 
    width: props.width ? props.width : 404
}))`        
    max-width: 100%;
`;  

export const EmptyMessage = styled.div.attrs({ 
})`         
    padding: 32px; 
    text-align: center;
    font-size: 14px;
    color: ${ props => props.theme.palette.colors.black };
`;

export const LoadCenter = styled.div.attrs({ 
})`         
    width: 20px;
    margin: 32px auto; 
    display: flex;
    justify-content: center;
`;

export const DecoratedScroll = styled.div.attrs({ 
})`

    overflow: auto; 

    ::-webkit-scrollbar-thumb {
        background-color: ${ props => props.theme.palette.secondary.main };
        outline: 0 solid ${ props => props.theme.palette.secondary.main };
    }

    ::-webkit-scrollbar {
        width: 1px;
        height: .2rem;
    }

    ::-webkit-scrollbar-track {  
    }
`;


export const FormContainer = styled.div.attrs({ 
})`
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-top: 24px;
`;

export const FormAction = styled.div.attrs({ 
})`
    max-width: 200px;
    width: 100%;
    ${
        p => p.big ? `
            max-width: 380px;
        ` : ``
    }
`;

export const GeneratedAction = styled.div.attrs({ 
})`
    max-width: 70px;
    width: 100%;
    margin-top: 24px;
    position: absolute;
    top: -12px;
    right: 24px;
`;

export const ManualAction = styled.div.attrs({ 
})`
    max-width: 280px;
    width: 100%;
    margin: 0 auto 24px;
`;

export const FormActions = styled.div.attrs({ 
})`
    display: flex;
    justify-content: flex-end;
    margin: 24px 0 48px;
    gap: 24px;
`;

export const BetweenRow = styled.div.attrs({ 
})`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
`;

export const MaxButton = styled.div.attrs({ 
})`
    max-width: 220px;
    width: 100%;
    ${
        p=>p.small? `
            max-width: 70px;
        ` : ``
    }
`;