import React, { useState } from "react"; 

import { Row, Col, Container } from 'reactstrap'; 
  
import {
    CommomContainer,
    BannerTitle,
    BannerText,
    TigerDecoration,
    TigerDecorationImage,
    TigerDecorationPlatform,
    BannerGameImage,
    ContainerDecorated
} from './styled'
import CardCommom from "../Card/Commom";
import DecorationEnd from "../DecorationEnd";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export default function CommomCases(){  
    
    const history = useHistory();
    const navigate = to => history.push(`/${ to }`);
 
    const cardCommom = {
        image: "/images/app-6.png",
        title:"Moedas e Recompensas",
        text:"Cada tarefa concluída recompensa os alunos com moedas virtuais.",
        supertitle:"Use essas moedas para",
        subtitle:" adquirir itens na loja de avatares.",
        subtext:"",
    }

    const secondCardCommom = {
        image: "/images/app-7.png",
        title:"Personalização do Avatar",
        text:"Os alunos podem personalizar seus avatares na loja de itens",
        supertitle:"Torne o aprendizado",
        subtitle:"ainda mais divertido.",
        subtext:"",
    }
 
    const thirdyCardCommom = {
        image: "/images/app-6.png",
        title:"Jogo da Memória da Royal Academy",
        text:"Uma ferramenta incrível para ajudar seus alunos a desenvolver habilidades cognitivas enquanto se divertem",
        supertitle:"Combinando",
        subtitle:" entretenimento e educação.",
        subtext:"esta modalidade é perfeita para revisar conteúdos e reforçar o aprendizado de forma lúdica",
    }

    const forthCardCommom = {
        image: "/images/app-7.png",
        title:"Quiz Competitivo",
        text:"Esta modalidade incentiva a rapidez de raciocínio e o espírito competitivo saudável",
        supertitle:"Coloque o conhecimento",
        subtitle:"dos seus alunos à prova.",
        subtext:"",
    }

    const [counter, setCounter] = useState(0)

    const openEasterEggGame = () => {
        if(counter < 6){
            setCounter(counter+1)
            return;
        }
        navigate("c3")

    }

    return ( 
        <> 
            <CommomContainer>  
                <ContainerDecorated>
                    <Container>
                        <Row>
                            <Col> 
                                <CardCommom blacked reverse card={cardCommom} />
                                <CardCommom blacked card={secondCardCommom} />
                                <CardCommom blacked reverse card={thirdyCardCommom} />
                                <CardCommom blacked card={forthCardCommom} />
                            </Col>
                        </Row>
                    </Container>
                    
                    <TigerDecoration>
                        <TigerDecorationImage onClick={openEasterEggGame} />
                        <TigerDecorationPlatform />
                    </TigerDecoration>

                    <Container>
                        <Row>
                            <Col> 
                                <BannerTitle>O aprendizado é uma aventura que vale a pena.</BannerTitle>
                                <BannerText>Desfrute da praticidade da correção automática de exercícios</BannerText>
                            </Col>
                        </Row>
                        <Row>
                            <Col> 
                                <BannerGameImage />
                            </Col>
                        </Row>
                    </Container>
                </ContainerDecorated>
            </CommomContainer>

            <DecorationEnd white />
        </>
    );
}