import { GET, POST, PUT, DELETE } from './api'

const table = "pagarme"

export const CreateCard = async (params) => {
    return await POST(`/${ table }/create-card-customer`, params, true);
}

export const CreateSubscription = async (params) => {
    return await POST(`/${ table }/subscription-plan`, params, true);
}

export const DeleteSubscription = async () => {
    return await DELETE(`/${ table }/subscription-plan`, true);
} 
 