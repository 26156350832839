import React from "react";  

import {  
    CardHome,
    CardHomeTitle
} from "./styled";

import ContainerAuthenticated from "containers/Authenticated";
import { Row, Col } from "reactstrap";

import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export default function DashboardCreatorLessons(){  

    const history = useHistory();
    const navigate = to => history.push(`/${ to }`);

    const options = [
        // { title:"Certo . Errado", action: () => navigate("dashboard/creator/tasks") },
        // { title:"Multipla Escolha", action: () => navigate("dashboard/creator/tasks") },
        // { title:"Associação", action: () => navigate("dashboard/creator/tasks") },
        // { title:"Jogo da memória", action: () => navigate("dashboard/creator/tasks") },
        
        // { title:"Conteúdo ", action: () => navigate("dashboard/creator/tasks") },
    ]

    return ( 
        <>
            <ContainerAuthenticated> 

                <Row>
                    <Col sm={12} md={6} lg={3}> 
                        <CardHome>
                            <CardHomeTitle>Aulas</CardHomeTitle>
                        </CardHome>
                    </Col>
                </Row>
                <Row>
                    {
                        options?.map((item, key) => 
                            <Col key={key} sm={12} md={6} lg={3}> 
                                <CardHome onClick={ item?.action }>
                                    <CardHomeTitle>{ item?.title }</CardHomeTitle>
                                </CardHome>
                            </Col> 
                        )
                    }
                </Row>
    
            </ContainerAuthenticated> 
        </>
    );
}