import React, { useEffect } from "react";  
import { useHistory } from 'react-router-dom';

import { Row, Col } from 'reactstrap';   
import { ReadObject } from "services/storage"; 
import { ThemedComponent } from "ui/theme";

import {
    SideBackgroundImageContainer,
    SideBackgroundImage,
    SideBackgroundImageDegree,

    FormContent,
    AppLogo,
    Content,
    Touch
} from './styled'
 

export default function ContainerUnauthenticated({ children, keep, flat }){   
    
    const history = useHistory();
    const navigate = to => history.push(`/${ to }`);

    const init = () => {
        const authentication = ReadObject('authentication')
        if (authentication?.jwt && !keep && !flat ) {
            completeNext()
        }
    }
 
    const completeNext = () => {
        navigate('dashboard')
    }

    useEffect(() => {  
        init()
        window.scrollTo(0,0)
    }, [])

    return ( 
        <> 
            <ThemedComponent>
                {
                    flat ? <FormContent flat>
                        {/* <Touch onClick={() => navigate('')}>
                            <AppLogo /> 
                        </Touch> */}
                        { children }
                    </FormContent> : <>
                        <Content>
                            <Row>
                                {/* <Col md={{ size:6 }} className="nopaddings">
                                    <SideBackgroundImageContainer>
                                        <SideBackgroundImage />
                                        <SideBackgroundImageDegree />
                                    </SideBackgroundImageContainer>
                                </Col> */}

                                <Col md={{ size:3 }} className="nopaddings" />
                                <Col md={{ size:6 }} className="nopaddings">
                                    <FormContent>
                                        <Touch onClick={() => navigate('')}>
                                            <AppLogo /> 
                                        </Touch>
                                        { children }
                                    </FormContent>
                                </Col>
                                <Col md={{ size:3 }} className="nopaddings" />

                            </Row>  
                        </Content>
                    </>
                }
            </ThemedComponent>
        </>
    );
}