import React from "react"; 
 
import Banner from "components/Landpage/Banner";
import HowWorks from 'components/Landpage/HowWorks'
import MoreInfos from 'components/Landpage/MoreInfos'
import CommomCases from 'components/Landpage/CommomCases'
import KnowMore from 'components/Landpage/KnowMore'
import LittleMore from 'components/Landpage/LittleMore' 

import ContainerLandpage from "containers/Landpage";
import { BannerSpacer, BannerText, BannerTitle } from "./styled";
import { Container } from "reactstrap";

export default function LegalHelp(){ 
 
    return ( 
        <ContainerLandpage > 
            
            <Container>
                <BannerTitle>Ajuda</BannerTitle>

                <BannerText>
                    <b>FAQ</b>

                    <p>
                        1. Como faço para criar uma conta no Royal Academy? <br />
                        Para criar uma conta, clique no botão "Inscrever-se" na página inicial e preencha as informações necessárias. Você receberá um e-mail de confirmação para ativar sua conta.
                    </p>
                    <p>
                        2. Esqueci minha senha. O que devo fazer? <br />
                        Se você esqueceu sua senha, clique em "Esqueci minha senha" na página de login e siga as instruções para redefinir sua senha. Você receberá um e-mail com um link para criar uma nova senha.
                    </p>
                    <p>
                        3. Como posso atualizar minhas informações pessoais? <br />
                        Para atualizar suas informações pessoais, faça login na sua conta, vá até "Configurações" e edite as informações que deseja atualizar. Lembre-se de salvar as alterações.
                    </p>
                    <p>
                        4. Quantos alunos e turmas posso criar? <br />
                        Você pode criar até 10 turmas, com um limite total de 400 alunos.
                    </p>
                    <p>
                        5. Como funcionam as imagens de perfil dos alunos? <br />
                        As imagens de perfil dos alunos são geradas por eles mesmos através da loja de avatares, onde eles podem escolher personagens e itens, posicioná-los e utilizar essa imagem como foto de perfil.
                    </p>
                    <p>
                        6. Posso cancelar minha conta? <br />
                        Sim, você pode cancelar sua conta a qualquer momento. Entre em contato conosco pelo e-mail sac@royalacademy.com.br e nós processaremos seu pedido de cancelamento.
                    </p>
                    <p>
                        7. Como entro em contato com o suporte ao cliente? <br />
                        Se você precisar de ajuda ou tiver dúvidas, entre em contato com nosso suporte ao cliente pelo e-mail sac@royalacademy.com.br
                    </p>



                    <b>Contato</b>
                    <p>
                        entre em contato com nosso suporte ao cliente pelo e-mail sac@royalacademy.com.br
                    </p>

                </BannerText>

                <BannerSpacer />

            </Container>
            
        </ContainerLandpage>
    );
}