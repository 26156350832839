import styled from 'styled-components' 

export const HeaderContainer = styled.div.attrs({ 
})`          
    position:relative;
    z-index:1;
    padding-top: 20px;
    min-height: 93px;
    max-height: 93px;
    width: 100%; 
    
    ${
        p => !p.land ? `
            background-color: ${ p.theme.palette.colors.backgroundblack };
            box-shadow: 0px 3px 6px ${ p.theme.palette.colors.shadow };
            
            min-height: 120px;
            max-height: 120px;
        ` : ``
    }
`;

export const RowCenter = styled.div.attrs({ 
})`          
    display: flex;
    align-items: center;

    @media(max-width: 767px){ 
        justify-content: space-between; 
    }
`;

export const RowEnd = styled.div.attrs({ 
})`          
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 0 20px;

    @media(max-width: 767px){ 
        display: none;
    }
`;


export const ContainerAppLogo = styled.div.attrs({ 
})`          
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const AppLogo = styled.img.attrs({ 
    src:'/logo1024.png'
})`          
    width: 100px;
    margin: 0 auto;
`;

export const HeaderLeftMenu = styled.ul.attrs({ 
})`        
    list-style: none;
    padding: 42px 0 0;
    margin:0; 
    height: 100%;
 
    @media(max-width: 767px){ 
        display: none;
    }

`;

export const HeaderRightMenu = styled.ul.attrs({ 
})`        
    list-style: none;
    padding: 42px 0 0;
    margin:0; 
    height: 100%; 
`;

export const HeaderMenuItem = styled.li.attrs({ 
})`     
    display: inline-flex; 
    height: 100%;
    align-items: center;
    padding: 0 15px;
    cursor: pointer; 
    color: ${ props => props.theme.palette.colors.white };
    font-size: 22px;  

    ${
        props => props.active ? `
            background-color: ${ props => props.theme.palette.colors.shadow };
            font-weight: bold;
        ` : ``
    }

    :hover{
        text-decoration: underline;
    }

    @media(max-width: 769px){ 
        padding: 0 12px; 
    }        
`;

export const HeaderMobile = styled.div.attrs({ 
})`           

    background: ${ p => p.theme.palette.colors.lightshadow };
    width: 44px;
    height: 44px;
    border-radius: 22px;

    min-width: 44px;
    min-height: 44px;
    max-width: 44px;
    max-height: 44px;
    border-radius: 22px;
    aspect-ratio: 1 / 1;

    display: flex;
    align-items: center;
    justify-content: center;
 
    position: fixed;
    z-index: 9;
    top: 62px;
    right: 10px;

    cursor: pointer;
    transition: all .3s ease;
    &:hover{ 
        transform: scale(1.05);
    }
     
`;

export const HeaderMenu = styled.div.attrs({ 
})`            
    position: relative;
    z-index: 1;
    padding: 10px;
    background: ${ p => p.theme.palette.colors.lightshadow };
    border-radius: 50%;

    aspect-ratio: 1 / 1;
    display: flex;
    align-items: center;
    justify-content: center;

    min-width: 44px;
    min-height: 44px;
    max-width: 44px;
    max-height: 44px;
    border-radius: 22px;
    aspect-ratio: 1 / 1;

    cursor: pointer;
    transition: all .3s ease;
    &:hover{ 
        transform: scale(1.05);
    }

    ${
        p => p.spacer ? `
            opacity: 0;
        ` : ``
    }
`;


export const HeaderMenuMenu = styled.div.attrs({ 
})`
    flex:1;
    width: 100%;
    display: flex;
    flex-direction: column;
`;

export const HeaderMenuMenuIcon = styled.img.attrs({ 
    src: "/images/mascot.png",
    width: 100
})` 
    margin: 24px auto 0;
    cursor: pointer;
    trasition: all .3s ease;
    &:hover{
        transform: scale(1.05);
    }
`;

export const MenuIcon = styled.img.attrs({ 
    src:'/icons/menu.svg'
})`           
`;


export const CloseIcon = styled.img.attrs({ 
    src:'/icons/close-white.svg'
})`           
`;

export const HeaderMobileMenu = styled.ul.attrs({ 
})`           
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    max-width: 320px;

    background-color: ${ props => props.theme.palette.colors.backgrounddark };
    list-style: none;
    padding: 24px 0 0 24px;
    margin:0;  
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    gap: 50px;
    z-index: 10;


`;

export const HeaderMobileItem = styled.li.attrs({ 
})` 
    display: flex; 
    height: 64px;
    width: 100%;
    align-items: center;
    padding: 0 32px;
    cursor: pointer;
 
    color: ${ props => props.theme.palette.colors.white };
    border-bottom: .5px solid ${ props => props.theme.palette.colors.whiteshadow };
    font-size: 15px;

    ${
        props => props.active ? `
            background-color: ${ props => props.theme.palette.colors.shadow };
            font-weight: bold;
        ` : ``
    }
    
    ${
        props => props.centred ? `
            justify-content: center;
        ` : ``
    }
    transition: all .3s ease;
    :hover{
        opacity: .8;
        transform: scale(1.05);
    }
`;



export const HeaderMenuMenuSpacer = styled.li.attrs({ 
})` 
    flex:1;
`;
    
    
// li .button-white-outline,
// li .button-white{
//     margin: 0;
// }


// export const Text = styled.div.attrs({ 
// })`         
//     font-family: '${ Theme.FONTS.light }';
//     font-size: 18px; 
//     color: ${ Theme.COLORS.black }; 
//     b{
//         font-family: '${ Theme.FONTS.bold }';
//         text-decoration: underline; 
//         cursor: pointer;
//     }
// `;   