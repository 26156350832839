import styled from 'styled-components' 

export const MoreInfosContainer = styled.div.attrs({ 
})`           
    padding: 45px 0px;
    position: relative;

    background: -moz-linear-gradient(180deg, ${ props => props.theme.palette.colors.black } 17%, ${ props => props.theme.palette.colors.backgrounddark } 60%);
    background: -webkit-linear-gradient(180deg, ${ props => props.theme.palette.colors.black } 17%, ${ props => props.theme.palette.colors.backgrounddark } 60%);
    background: linear-gradient(180deg, ${ props => props.theme.palette.colors.black } 17%, ${ props => props.theme.palette.colors.backgrounddark } 60%);
`; 

export const MoreInfosContainerDecorated = styled.div.attrs({ 
})`           
    margin-bottom: -5vh;
`; 
 

export const BannerTitle = styled.div.attrs({ 
})`           
    margin: 15vh 0 50px;
    color: ${props => props.theme.palette.colors.white }; 
    text-align: center;
    font-size: 72px;
    line-height: 84px;
    font-weight: 900;
    @media(max-width: 600px){
        font-size: 32px;
        line-height: 44px;
    }
`; 

export const BannerText = styled.div.attrs({ 
})`           
    color: ${props => props.theme.palette.colors.white }; 
    text-align: center;
    font-size: 25px;
    font-weight: 700;
`; 
 





 

 