import styled from 'styled-components'   
import { hexToRgb } from 'ui/styled';
  

export const DashboardMenuContainer = styled.div.attrs({ 
})`           
    position: fixed;
    top:0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 100;
    background: ${ props => props.theme.palette.colors.shadow };
`;

export const DashboardMenu = styled.div.attrs({ 
    className:'menu-contant'
})`           
    max-width: 180px;
    background: ${ props => props.theme.palette.colors.white };
    width: 100%;
    min-height: calc(100vh - 60px);
    max-height: calc(100vh - 60px);
    display: flex;
    flex-direction: column;
    overflow: auto;

    border-right: .5px solid  ${ props => props.theme.palette.colors.backgrounddark };

    // @media(max-width: 767px) { 
    //     max-width: 120px;
    // }
`;

export const DashboardMenuHeader = styled.div.attrs({ 
})`           
    height: 60px;
    width: 100%; 
    display: flex;
    align-items: center;
    padding: 0 20px;

    text-transform: uppercase;
    font-size: 15px;
    color: ${ props => props.theme.palette.colors.white };
    cursor: pointer;

    background: ${ props => props.theme.palette.primary.main };
    background: linear-gradient(48deg, rgba(${props => props.theme.palette.primary.main},1) 0%, rgba(${props => props.theme.palette.primary.main},.9) 21%, rgba(${props => props.theme.palette.primary.main},.75) 49%, rgba(${props => props.theme.palette.primary.main},.6) 87%, rgba(${props => props.theme.palette.primary.main},.45) 100%);
`;

export const DashboardMenuHeaderIcon = styled.img.attrs({ 
})`           
    margin-right: 20px;
    cursor: pointer;
`;

export const DashboardMenuHeaderUserContent = styled.div.attrs({ 
})`           
    padding: 0px 0px;
    margin-bottom: 28px;
    // background: linear-gradient(33deg, transparent 50%, ${ props => props.theme.palette.colors.backgrounddark } 50%);
    background: linear-gradient(-30deg, transparent 52%, ${ props => props.theme.palette.colors.backgrounddark } 52%);

    

    @media(max-width: 767px) { 
        background: linear-gradient(-22deg, transparent 50%, ${ props => props.theme.palette.colors.backgrounddark } 50%);
    }



    transition: all .3s ease;
    // animation: random-rotation 10s infinite ease; 
    
    animation: rrotate 240s ease-in-out infinite;

    @keyframes rrotate {
        0% {
            background: linear-gradient(-15deg, transparent 52%, ${ props => props.theme.palette.colors.backgrounddark } 52%);
        }
        12.5% {
            background: linear-gradient(-30deg, transparent 52%, ${ props => props.theme.palette.colors.backgrounddark } 52%);
        }
        25% {
            background: linear-gradient(-60deg, transparent 52%, ${ props => props.theme.palette.colors.backgrounddark } 52%);
        }
        37.5% {
            background: linear-gradient(-90deg, transparent 52%, ${ props => props.theme.palette.colors.backgrounddark } 52%);
        }
        50% {
            background: linear-gradient(-120deg, transparent 52%, ${ props => props.theme.palette.colors.backgrounddark } 52%);
        }
        62.5% {
            background: linear-gradient(-90deg, transparent 52%, ${ props => props.theme.palette.colors.backgrounddark } 52%);
        }
        75% {
            background: linear-gradient(-60deg, transparent 52%, ${ props => props.theme.palette.colors.backgrounddark } 52%);
        }
        87.5% {
            background: linear-gradient(-30deg, transparent 52%, ${ props => props.theme.palette.colors.backgrounddark } 52%);
        }
        100% {
            background: linear-gradient(-15deg, transparent 52%, ${ props => props.theme.palette.colors.backgrounddark } 52%);
        } 
    }
`;

export const DashboardMenuHeaderUserImage = styled.div.attrs({ 
})`           
    width: 100px;
    height: 100px; 
    border-radius: 50px; 
    background: rgba(${ props => hexToRgb(props.theme.palette.colors.white) },1) url(/logo1024.png) no-repeat center center / cover;
    margin: 0 auto 12px;
    overflow: hidden;

    cursor: pointer;
    transition: all .3s ease;
    &:hover{
        transform: scale(1.02);
    }


    @media(max-width: 767px) { 
        width: 60px;
        height: 60px; 
        border-radius: 30px; 
    }
`;

export const DashboardMenuHeaderUserText = styled.div.attrs({ 
})`           
    font-size: 15px;
    font-weight: bold;
    color: ${ props => props.theme.palette.colors.grey };
    margin-bottom: 12px; 
`;

export const DashboardMenuOption = styled.div.attrs({ 
})`           
    padding: 12px 30px 12px;
    font-size: 18px;
    color: ${ props => props.theme.palette.colors.black };
    cursor: pointer; 
    transition: all .3s ease;

    &:hover{
        color: ${ props => props.theme.palette.colors.backgrounddark };
    }

    &:before{
        content: " • ";
        color: ${ props => props.theme.palette.colors.backgrounddark };
        font-size: 18px;
    }
    
    ${
        props => props.active ? `
            background: ${ props.theme.palette.primary.main };
            font-size: 15px;
            font-weight: bold;
            color: ${ props.theme.palette.colors.white };
        ` : ``
    }

    ${
        props => props.submenu ? ` 
            font-size: 14px;
            padding: 6px 46px;
            display: flex;
            &:hover{
                transform: scale(1.04); 
            }
            &:before{
                content: " ¬ ";
                font-size: 18px;
                transform: rotate(180deg) translateY(3px) translateX(5px);
            }
        ` : ``
    }
`;

export const DashboardMenuContent = styled.div.attrs({ 
})` 
`;

export const DashboardMenuFooter = styled.div.attrs({ 
})`
    padding: 20px 20px 0 ;
    z-index:2;
`;

export const DashboardVersionContent = styled.div.attrs({ 
})` 
    margin: 24px 0;
`;

export const DashboardVersionText = styled.div.attrs({ 
})`
    font-size: 14px;
    font-weight: bold;
    color: ${ props => props.theme.palette.colors.white };
    text-align: center; 
`;

export const DashboardMenuSpacer = styled.div.attrs({ 
})`
    flex:1;
`;


export const DashboardMenuUniLogo = styled.div.attrs({ 
})`
    width: 54px;
    height: 54px;
    background: url(/images/uni.png) no-repeat center center / contain;
    margin: 0 auto;

    transition: all .3s ease;
    @media(max-width: 767px) { 
        width: 32px;
        height: 32px;
    }
`;



export const DashboardMenuDecoration = styled.div.attrs({ 
})`
    position: fixed;
    bottom: -70px;
    left: -105px;
    
    bottom: -100px;
    left: -112px;
    
    

    height: 180px; /* control the size */
    aspect-ratio: 1.8;
    --g: radial-gradient(50% 50%, #000 98%, #0000) no-repeat;
    mask: var(--g) 100% 100%/30% 60%,var(--g) 70% 0/50% 100%,var(--g) 0 100%/36% 68%,var(--g) 27% 18%/26% 40%,linear-gradient(#000 0 0) bottom/67% 58% no-repeat;
    background: ${ props => props.theme.palette.colors.backgrounddark };;

    z-index:0;
`;






