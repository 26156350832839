import React from "react"; 

import {
    MoreInfosItemContent,
    MoreInfosImage,
    MoreInfosItemText,
    MoreInfosItemContainer,
    MoreInfosItemTitle
} from './styled' 
import { goSection } from "utils";

export default function MoreInfosItem(props){ 

    return ( 
        <> 
            <MoreInfosItemContent secondary={props.secondary} onClick={() => goSection(props.target)}>
                <MoreInfosImage image={props.image}>
                    <MoreInfosItemContainer>
                        <MoreInfosItemTitle>{ props.title }</MoreInfosItemTitle>
                        <MoreInfosItemText>{ props.text }</MoreInfosItemText>
                    </MoreInfosItemContainer>
                </MoreInfosImage>
            </MoreInfosItemContent> 
        </>
    );
}