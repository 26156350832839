import styled from 'styled-components' 

import Lottie from 'react-lottie';

export const CommomContainer = styled.div.attrs({ 
})`          
    position: relative;
    padding-top: 140px;
    padding-bottom: 140px;
`;

export const CommomDecoration = styled.img.attrs({ 
    src:'/icons/circle-2.svg'
})`          
    position: absolute;
    width: 793px;
    right: -320px;
    bottom: -280px;
`;

export const CommomTitle = styled.h1.attrs({  
})`          
    font-size: 32px;
    font-weight: bold;
    color: ${ props => props.theme.palette.secondary.main };
    margin: 0;
`;  

export const CommomList = styled.ul.attrs({  
})`          
    list-style: none;
    padding: 0;
    margin-top: 70px;
    margin-left: 20px;
`;  

export const CommomListItem = styled.li.attrs({  
})`          
    font-size: 20px;
    margin-bottom: 20px;
    color: ${ props => props.theme.palette.colors.grey };
    
    &:before{
        content: "\\2022"; 
        color: ${ props => props.theme.palette.primary.main }; 
        font-weight: bold; 
        display: inline-block; 
        width: 1em; 
        margin-left: -1em;
    }
`;  

export const CommomAnimation = styled(Lottie).attrs( props => ({  
    options:{
        loop: true,
        autoplay: true, 
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        },
        animationData: props.animationData
    },
    // height: 500,
    // width: 500
}))`           
    margin-top: 140px ;
    max-width: 499px;
    width: 100%;
`;  


export const BannerTitle = styled.div.attrs({ 
})`           
    margin: 15vh 0 50px;
    color: ${props => props.theme.palette.colors.black }; 
    text-align: center;
    font-size: 72px;
    line-height: 84px;
    font-weight: 900;
    @media(max-width: 600px){
        font-size: 32px;
        line-height: 44px;
    }
`; 

export const BannerText = styled.div.attrs({ 
})`           
    color: ${props => props.theme.palette.colors.black }; 
    text-align: center;
    font-size: 25px;
    font-weight: 700;
`; 













export const TigerDecoration = styled.div.attrs({ 
})`
    padding: 10vh 0 0; 
    display: flex;
    flex-direction: column;
    align-items: flex-end;
`; 

export const TigerDecorationImage = styled.img.attrs({ 
    src: '/images/mascot.png',
    width: 200
})`
    margin-right: calc( 7% - 50px );
    cursor: pointer;
    transition: all .3s ease;
    &:hover{
        transform: scale( 1.05 );
    }
`; 


export const TigerDecorationPlatform = styled.div.attrs({ 
})`
    width: 15%;
    height: 10px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    background: ${props => props.theme.palette.colors.backgrounddark }; 
`; 




export const BannerGameImage = styled.div.attrs({ 
})`
    margin: 10vh auto 0;
    width: 50%;
    aspect-ratio: 2 / 2 ;
    background: url(/images/app-1.png) no-repeat center center / contain; 
    z-index:2;
    position: relative;
    @media(max-width:991px) {
        aspect-ratio: 2 / 3 ;
        width: 80%;
    }

`; 


export const ContainerDecorated = styled.div.attrs({ 
})`           
    margin-bottom: -20vh;
    z-index:2;
`; 
 