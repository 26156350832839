import styled from 'styled-components'  

import {
    Animation
} from 'ui/styled'

export const DashboardTitle = styled.div.attrs({ 
})`            
    font-size: 22px;
    font-weight: bold;
    color: ${ props => props.theme.palette.colors.black };
    margin-bottom: 24px;
    ${
        props => props.centred ? `
            text-align: center;
        ` : ``
    }
`;

export const DashboardText = styled.div.attrs({ 
})`            
    font-size: 16px;
    line-height: 26px;
    color: ${ props => props.theme.palette.colors.black };
    ${
        props => props.centred ? `
            text-align: center;
        ` : ``
    }
`;

export const DashboardAnimation = styled(Animation).attrs({ 
    width: '100%',
    height: 420
})`             
`;




export const CardHome = styled.div.attrs({ 
})`
    min-height: 110px;
    border: .5px solid  ${ props => props.theme.palette.colors.backgrounddark };
    margin: 0 0 24px;

    box-shadow: 1px 3px 6px ${ props => props.theme.palette.colors.lightshadow };

    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    border-bottom-left-radius: 16px;

    overflow: hidden;

    cursor: pointer;
    transition: all .3s ease;
    &:hover{
        transform: scale(1.02);
    }
`;

export const CardHomeTitle = styled.div.attrs({ 
})`
    font-size: 18px;
    font-weight: 600;
    padding: 12px 12px;

    background:  ${ props => props.theme.palette.colors.backgrounddark };
    color:  ${ props => props.theme.palette.colors.white };
`;


export const CardHomeDecoration = styled.div.attrs({ 
})`
    height: 140px;
    width: 100%;
    background: url(${ p => p.image }) no-repeat bottom+25% center / cover;
    position:relative;
    z-index: 2;
    // filter: blur(1px);
    
    ${
        p => p?.reverse ? `
            border-bottom-right-radius: 100%;
            height: 20px;
        ` : `
           
        `
    }
`;






export const CardQuestion = styled.div.attrs({ 
})`
    display: flex;
    flex-direction: column;
    box-shadow: 1px 3px 6px ${ props => props.theme.palette.colors.lightshadow };
    border: .5px solid  ${ props => props.theme.palette.colors.backgrounddark };
    margin-bottom: 32px;


    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;

    cursor: pointer;
    transition: all .3s ease;
    &:hover{
        transform: scale( 1.00 );
        
    }

    & .remove-button{
        display:none;
    }

    &:hover .remove-button{
        display:block;
    }

    &:hover .card-content-text{
        min-height: 35px;
        max-height: 35px;
    }
`;

export const CardQuestionDecoration = styled.div.attrs({ 
})`
    background:  ${ props => props.theme.palette.colors.backgrounddark };
    padding: 6px 12px;
    font-size: 16px;
    font-weight: 400;
    color: ${ props => props.theme.palette.colors.white };
    margin: -4px 0 0 auto;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
`;

export const CardQuestionTitle = styled.div.attrs({ 
    className:"card-content-text"
})`
    font-size: 14px;
    padding: 12px 12px;

    min-height: 79px;
    max-height: 79px;

    margin: 0px auto 12px;

    overflow: hidden;
`;

export const CardQuestionIcon = styled.img.attrs({ 
    width: 32
})`
    margin: -32px auto 0 8px ;
    filter: hue-rotate(180deg); 
`;

export const CardQuestionIconDecoration = styled.div.attrs({ 
})`
`;

export const CardQuestionButton = styled.div.attrs({ 
})`
    display: flex;
    width: 120px;
    margin: 0 auto 12px 12px;
`;
