import { CoreContext } from "context/CoreContext";
import moment from "moment/moment";
import React, { useContext, useEffect, useState } from "react";  

import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify"; 
import { Create, ReadOne, Update } from "services/students";
import { exposeStrapiError, goSection, normalizeStrapiRegister } from "utils";

export default function useController(){  

    const history = useHistory();
    const navigate = to => history.push(`/${ to }`);

    const params = useParams()

    const { user } = useContext(CoreContext)

    const [saving, setSaving] = useState(false)
    const [loading, setLoading] = useState(false) 
    
    const [manual, setManual] = useState(false)
    const [editing, setEditing] = useState(false)  
    
    const [ form, setForm ] = useState({})
    const formValue = ref => { return form?.[ref] ? form?.[ref] : '' ;}
    const changeForm = ( value, ref ) => { setForm({ ...form, [ref]: value }) ;} 

    const saveForm = async () => {
        await save(form)
    }

    const save = async (ff, many) => {
        if(!ff?.name){ return ;}
        if(!many){ setSaving(true) ;}
        const payload = {
            owner: user?.id,
            name: ff?.name
        }

        const result = editing ? await Update({ data: payload }, params?.id) : await Create({ data: payload })

        if(result && !exposeStrapiError(result)){
            toast.success("Aluno salvo com sucesso")
            if(!many){ navigate("dashboard/contentor/students") ;}else{
                return true;
            }
        }
        if(!many){ setSaving(false) ;}
    }

    const init = async () => {
        setLoading(true)
            const result = await ReadOne(params?.id)
            const normalResult = normalizeStrapiRegister(result)
            
            const nextForm = {
                ...normalResult
            }

            console.log("nextForm", nextForm)

            setForm(nextForm)
            setManual(true)
            setEditing(true)
        setLoading(false)
    }

    useEffect(() => {
        if(params?.id){ init() ;}
    }, [params])

    return { 
        loading,  
        saving,

        formValue,
        changeForm,
        
        save, 
        saveForm, 
  
        editing, 
    }
}