import styled from 'styled-components' 

export const Content = styled.div.attrs({ 
})`          
    overflow:hidden;
`;
 


export const BannerTitle = styled.div.attrs({ 
})`           
    margin: 15vh 0 50px;
    color: ${props => props.theme.palette.colors.black }; 
    font-size: 72px;
    line-height: 84px;
    font-weight: 900;
    @media(max-width: 600px){
        font-size: 32px;
        line-height: 44px;
    }
`; 

export const BannerText = styled.div.attrs({ 
})`
    color: ${props => props.theme.palette.colors.black }; 
    font-size: 24px;
    p {
        margin-bottom: 48px;
    }
`;
 

export const BannerSpacer = styled.div.attrs({ 
})`
    padding: 40px 0;
`;
 


