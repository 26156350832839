import React from "react"; 
 
import Banner from "components/Landpage/Banner";
import HowWorks from 'components/Landpage/HowWorks'
import MoreInfos from 'components/Landpage/MoreInfos'
import CommomCases from 'components/Landpage/CommomCases'
import KnowMore from 'components/Landpage/KnowMore'
import LittleMore from 'components/Landpage/LittleMore' 

import ContainerLandpage from "containers/Landpage";
import { BannerSpacer, BannerText, BannerTitle } from "./styled";
import { Container } from "reactstrap";

export default function LegalPrivacity(){ 
 
    return ( 
        <ContainerLandpage > 
            
            <Container>
                <BannerTitle>Politica de privacidade</BannerTitle>

                <BannerText>
                    <b>1. Introdução</b>
                    <p>
                        Nós valorizamos sua privacidade e estamos comprometidos em proteger suas informações pessoais. Esta Política de Privacidade descreve como coletamos, usamos, divulgamos e protegemos suas informações no Royal Academy.
                    </p>

                    <b>2. Coleta de Informações</b>
                    <p>
                        As informações, como o nome do aluno, são inseridas pelo professor. A imagem de perfil de cada aluno será gerada pelos próprios alunos através da loja de avatares, onde eles poderão escolher personagens e itens, posicioná-los e, então, utilizar esta imagem como foto de perfil.
                    </p>

                    <b>3. Uso das Informações</b>
                    <p>
                        Usamos as informações coletadas para fornecer e melhorar nossos serviços, personalizar a experiência dos usuários, e cumprir com obrigações legais.
                    </p>

                    <b>4. Compartilhamento de Informações</b>
                    <p>
                        Nós não vendemos nem compartilhamos suas informações pessoais com terceiros. Todas as informações são usadas exclusivamente para o funcionamento do Royal Academy.
                    </p>

                    <b>5. Segurança das Informações</b>
                    <p>
                        Implementamos medidas de segurança para proteger suas informações contra acesso não autorizado, alteração, divulgação ou destruição. No entanto, nenhuma transmissão de dados pela internet ou método de armazenamento eletrônico é completamente seguro.
                    </p>

                    <b>6. Seus Direitos</b>
                    <p>
                        Você tem o direito de acessar, corrigir, atualizar ou excluir suas informações pessoais. Para exercer esses direitos, entre em contato conosco pelo e-mail sac@royalacademy.com.br.
                    </p>

                    <b>7. Alterações a esta Política</b>
                    <p>
                        Podemos atualizar esta Política de Privacidade de tempos em tempos. Notificaremos você sobre quaisquer alterações significativas publicando a nova política em nosso site e atualizando a data no topo deste documento.
                    </p>

                    <b>8. Contato</b>
                    <p>
                        Se você tiver dúvidas sobre esta Política de Privacidade, entre em contato conosco pelo e-mail sac@royalacademy.com.br
                    </p>
                </BannerText>

                <BannerSpacer />

            </Container>
            
        </ContainerLandpage>
    );
}