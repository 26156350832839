import styled from 'styled-components' 

export const Content = styled.div.attrs({ 
})`          
    overflow:hidden;
`;
 
export const ContentBody = styled.div.attrs({ 
})`          
    min-height: calc(100vh - 0px);
    display: flex;
    align-items: center;
    justify-content: center;
`;
  
export const ContentAnimation = styled.div.attrs({ 
})`          
    margin-top: -15vh;
    display: flex;
    flex-direction: column;
    gap: 10vh;
`;
 




export const IframeGame = styled.iframe.attrs({ 
    src:"https://c3.royalacademy.com.br"
})`          
    height: 100vh;
    width: 100%;
`;
 

  
export const GameLoading = styled.div.attrs({ 
})`          
    position: absolute;
    inset: 0 0 0 0;
    background: ${ p => p.theme.palette.colors.white };
    z-index:8;
    display: flex;
    align-items: center;
    justify-content: center;
    ${
        p => p.close ? `
            display: none;
        ` : ``
    }
`;
 
 

  
export const GameArea = styled.div.attrs({ 
})`          
    position: relative;
    width: 100%;
`;
 

  
export const GameImageLoading = styled.img.attrs({ 
    src:"/logo1024.png"
})`          
    width: 180px;

     

    transform: rotate(0deg) ;
    animation: rotateMe infinite 2s ease-in-out; 
  
    @keyframes rotateMe {
        0% {
            transform: rotate(0deg) ;
        }
        
        50% { 
            transform: rotate(180deg) ;
        }
        
        100% {
            transform: rotate(360deg) ;
        }
    }
  
`;
 