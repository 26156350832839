import React from "react";  

import {  
    DashboardSideMascot, 
    DashboardSideMascotBaloon
} from "./styled";


export default function CreatorMascotIA({ editing, generated, onClick }){  
    return ( 
        <>
            {
                editing ? null : 
                    <DashboardSideMascot generated={generated?.length} onClick={onClick}>
                        <DashboardSideMascotBaloon>
                            Gere conteúdo com <b>IA</b> !!
                        </DashboardSideMascotBaloon>
                    </DashboardSideMascot>
            } 
        </>
    );
}