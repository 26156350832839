import styled from 'styled-components'  
 
 
export const FormTitle = styled.div.attrs({ 
})`           
    color: ${ props => props.theme.palette.colors.black }; 


    font-size: 40px;
    line-height: 46px;
    font-weight: 900;

    margin: 20px 0 10px;
    ${ p => p.spaced ? `
        margin: 20px 12px 10px;
    ` : `` }
`;
export const FormSubtitle = styled.div.attrs({ 
})`           
    color: ${ props => props.theme.palette.colors.black }; 

    font-size: 48px;
    line-height: 54px;
    font-weight: 900;

    padding-top: 10px;
    margin-bottom: 60px;
`;
export const FormSupertext = styled.div.attrs({ 
})`           
    color: ${ props => props.theme.palette.colors.black }; 

    font-size: 22px;
    line-height: 26px;
    font-weight: 900;

    padding: 10px 0;
`;
export const FormText = styled.div.attrs({ 
})`           
    font-size: 18px; 
    color: ${ props => props.theme.palette.colors.black };
    margin: 0px 0 30px;
    b {
        color: ${ props => props.theme.palette.colors.green };
        cursor: pointer;
        &:hover{
            text-decoration: underline;
        }
    }

    ${
        p => p.big ? `
            font-size: 28px;
            font-weight: 300;
        ` : ``
    }
`;

export const FormSpacer = styled.div.attrs({ 
})`           
    margin-top: 40px;
`; 