import React from "react";  

import {  
    CardHome,
    CardHomeSquare,
    CardHomeText,
    CardHomeTitle,
    DashboardTitle
} from "./styled";

import ContainerAuthenticated from "containers/Authenticated";
import { Row, Col } from "reactstrap";

import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export default function DashboardContentor(){  

    const history = useHistory();
    const navigate = to => history.push(`/${ to }`);

    const options = [
        { title:"Tarefas", action: () => navigate("dashboard/contentor/tasks"), image:"/images/dash/tiger-1.jpg", description:"Em tarefas você pode criar as suas questões e conteúdo" },
        { title:"Alunos", action: () => navigate("dashboard/contentor/students"), image:"/images/dash/tiger-3.jpg", description:"Cadastre seus alunos e receba o QRCode de acesso" },
        { title:"Aulas", action: () => navigate("dashboard/contentor/tasks"), image:"/images/dash/tiger-2.jpg", description:"Organize tarefas cadastrar e monte suas aulas" },
        // { title:"Turmas", action: () => navigate("dashboard/contentor/tasks"), image:"/images/dash/tiger-4.jpg", description:"Monte suas turmas com os alunos cadastrados" },
    ]

    return ( 
        <>
            <ContainerAuthenticated> 
                
                <DashboardTitle>Acervo de</DashboardTitle>
                <Row>
                    {
                        options?.map((item, key) => 
                            <Col key={key} sm={12} md={6} lg={4}> 
                                <CardHome onClick={ item?.action } image={item.image}>
                                    <CardHomeSquare>
                                        <CardHomeTitle>{ item?.title }</CardHomeTitle>
                                        <CardHomeText>{ item?.description }</CardHomeText>
                                    </CardHomeSquare>
                                </CardHome>
                            </Col> 
                        )
                    }
                </Row>
    
            </ContainerAuthenticated> 
        </>
    );
}