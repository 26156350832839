import React, { useContext, useEffect, useState } from "react";  

import {  
    CardHome,
    CardHomeDecoration,
    CardHomeTitle,
    CardQuestion,
    CardQuestionButton,
    CardQuestionDecoration,
    CardQuestionIcon,
    CardQuestionIconDecoration,
    CardQuestionTitle,
    DashboardTitle
} from "./styled";

import ContainerAuthenticated from "containers/Authenticated";
import { Row, Col } from "reactstrap";

import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Delete, ReadByUser } from "services/questions";
import { CoreContext } from "context/CoreContext";
import { exposeStrapiError, normalizeStrapiList } from "utils";
import { mockedQuestions } from "utils/options";
import Button from "components/Form/Button";
import { BetweenRow, Load, LoadCenter, MaxButton } from "ui/styled";
import { toast } from "react-toastify";

export default function DashboardCreatorTasks(){  

    const history = useHistory();
    const navigate = to => history.push(`/${ to }`);

    const { user } = useContext(CoreContext)

    const [questions, setQuestions] = useState([]) 
    const [loading, setLoading] = useState(false) 

    const options_content = [
        // { type:"content", title:"Conteúdo", action: () => navigate("dashboard/creator/game/content"), image:"/images/games/g6.jpg" },
        // { type:"contentvideo", title:"Conteúdo (Vídeo) ", action: () => navigate("dashboard/creator/game/contentvideo"), image:"/images/games/g1.jpg" }, 
    ]

    const options = [ 
        { type:"content", title:"Conteúdo", action: () => navigate("dashboard/creator/game/content"), image:"/images/games/g8.jpg" },
        
        { type:"rightwrong", title:"Certo . Errado", action: () => navigate("dashboard/creator/game/rightwrong"), image:"/images/games/g2.jpg" },
        { type:"multichoice", title:"Multipla . Escolha", action: () => navigate("dashboard/creator/game/multichoice"), image:"/images/games/g3.jpg" },
        
        // { type:"association", title:"Associação", action: () => navigate("dashboard/creator/game/association"), image:"/images/games/g4.jpg" },
        // { type:"memory", title:"Jogo da memória", action: () => navigate("dashboard/creator/game/memory"), image:"/images/games/g5.jpg" },
        // { type:"gibbet", title:"Jogo da forca", action: () => navigate("dashboard/creator/game/gibbet"), image:"/images/games/g7.jpg" },
        // { type:"crossword", title:"Palavras cruzadas", action: () => navigate("dashboard/creator/game/crossword"), image:"/images/games/g8.jpg" },

    ]

    const init = async () => {
        setLoading(true)
        const result = await ReadByUser(user?.id)
        if(result && !exposeStrapiError(result)){
            const normalResult = normalizeStrapiList(result)
            setQuestions(normalResult?.sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime() ))
        }
        setLoading(false)
    }

    const remove = async (item) => {
        setLoading(true)
        const result = await Delete(item?.id)
        if(result && !exposeStrapiError(result)){
            toast.success("Removido")
            init()
        }
        setLoading(false)
    }

    const parseTitle = (type) => {
        return mockedQuestions?.find(f => f.type === type)?.title
    }

    useEffect(() => { init() ;}, [])

    return ( 
        <>
            <ContainerAuthenticated> 


                <BetweenRow>
                    <DashboardTitle>Criador de tarefas</DashboardTitle>
                    <MaxButton>
                        <Button nospace onClick={() => navigate("dashboard/contentor/tasks")} outline primary>Ir para Acervo</Button>
                    </MaxButton>
                </BetweenRow>
 
                <Row>
                    {
                        options_content?.map((item, key) => 
                            <Col key={key} sm={12} md={6} lg={3}> 
                                <CardHome onClick={ item?.action }>
                                    <CardHomeDecoration image={item.image} />
                                    <CardHomeTitle>{ item?.title }</CardHomeTitle>
                                </CardHome>
                            </Col> 
                        )
                    }
                    {
                        options?.map((item, key) => 
                            <Col key={key} sm={12} md={6} lg={3}> 
                                <CardHome onClick={ item?.action }>
                                    <CardHomeDecoration image={item.image} />
                                    <CardHomeTitle>{ item?.title }</CardHomeTitle>
                                </CardHome>
                            </Col> 
                        )
                    }
                </Row> 

            </ContainerAuthenticated> 
        </>
    );
}