import React, { useContext, useState } from "react"; 

import { 
    FormSteperButton
} from './styled'

import { Load } from "components/Form/Button/styled";


export default function CustomButton({ onClick, children, loading }){  
    return ( 
        <>   
            <FormSteperButton onClick={onClick}> 
                { loading ? <Load backed /> : children }
            </FormSteperButton>  
        </>
    );
}