import React from "react"; 
 
import Banner from "components/Landpage/Banner";
import HowWorks from 'components/Landpage/HowWorks'
import MoreInfos from 'components/Landpage/MoreInfos'
import CommomCases from 'components/Landpage/CommomCases'
import KnowMore from 'components/Landpage/KnowMore'
import LittleMore from 'components/Landpage/LittleMore' 

import ContainerLandpage from "containers/Landpage";
import { BannerSpacer, BannerText, BannerTitle } from "./styled";
import { Container } from "reactstrap";

export default function LegalAbout(){ 
 
    return ( 
        <ContainerLandpage > 
            
            <Container>
                <BannerTitle>Sobre</BannerTitle>

                <BannerText>

                    <b>Educação e Tecnologia Inteligente</b>
                    <p>
                        Na Royal Academy, acreditamos que a educação deve ser envolvente, eficiente e acessível. Nossa missão é capacitar professores com ferramentas inovadoras que simplificam a criação e correção de conteúdos, permitindo que eles se concentrem no que fazem de melhor: ensinar. Com o auxílio da inteligência artificial, nosso gerador de questões e sistema de correção automática transformam a experiência de aprendizado, tornando-a mais dinâmica e interativa.
                    </p>

                    <p>
                        Nós entendemos que cada aluno é único e merece uma abordagem personalizada. Por isso, desenvolvemos uma plataforma que permite aos professores criar perguntas adaptadas às necessidades individuais de seus alunos. Além disso, nossos avatares divertidos e personalizados tornam o ambiente de aprendizado mais atraente e motivador para os estudantes.
                    </p>

                    <p>
                        Na Royal Academy, estamos comprometidos com a excelência e inovação na educação. Nossa equipe é composta por educadores, desenvolvedores e designers dedicados a criar soluções tecnológicas que realmente fazem a diferença. 
                    </p>

                    <p>
                        Participe dessa revolução na educação! Inscreva-se hoje e descubra como a Royal Academy pode transformar suas aulas.
                    </p>

                </BannerText>

                <BannerSpacer />

            </Container>
            
        </ContainerLandpage>
    );
}