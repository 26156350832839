import React from "react"; 
 

import { 
    SectionEndDecoration, 
    SectionPaint,
    SectionPaintMinor,
    SectionPaintBlobDrip
} from './styled' 

export default function DecorationEnd({ white, reverse }){ 
    
    return ( 
        <>  
            <SectionEndDecoration reverse={reverse} white={white}>
                <SectionPaint reverse={reverse} white={white} />
                <SectionPaintMinor reverse={reverse} white={white} />
                <SectionPaintBlobDrip reverse={reverse} white={white} />
            </SectionEndDecoration>
        </>
    );
}