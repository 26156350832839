import styled from 'styled-components' 

export const CardCommomContainer = styled.div.attrs({ 
})`
    position: relative;
    z-index:2;
    display: flex;
    padding: 15vh 0 0;
    align-items: center;
    ${ p => p.reverse ? `
        flex-direction: row-reverse;
    ` : ``}

    @media(max-width: 991px){
        flex-direction: column;
        align-items: center;
    }
`; 
export const CardCommomImage = styled.div.attrs({ 
})`
    width: 45%;
    min-width: 45%;
    aspect-ratio: 1 / 1;
    background: url(${ p => p.image }) no-repeat center center / contain ; 

    ${ p => p.blacked ? `
        background: url(${ p.image }) no-repeat center center / contain ; 
    ` : ``}

    @media(max-width: 991px){

        width: 70%;
        min-width: 70%;
        aspect-ratio: 2 / 3;
    }
`; 
export const CardCommomContent = styled.div.attrs({ 
})`
    padding: 4vh 4vw;
`; 
export const CardCommomContentTitle = styled.div.attrs({ 
})`
    color: ${props => props.theme.palette.colors.white }; 
    font-weight: 900;
    font-size: 48px; 

    ${ p => p.blacked ? `
        color: ${ p.theme.palette.colors.black }; 
    ` : ``}

    @media(max-width: 767px){
        font-size: 32px;
    }
`; 
export const CardCommomContentText = styled.div.attrs({ 
})`
    color: ${props => props.theme.palette.colors.white }; 
    font-weight: 700;
    font-size: 18px;

    margin: 16px 0 22px;

    ${ p => p.blacked ? `
        color: ${ p.theme.palette.colors.black }; 
    ` : ``}
`; 