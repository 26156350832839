import React from "react";  

import {  
    CardHome,
    CardHomeTitle,
    DashboardTitle
} from "./styled";

import ContainerAuthenticated from "containers/Authenticated";
import { Row, Col } from "reactstrap";

import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export default function DashboardCreatorTaskContentVideo(){  

    const history = useHistory();
    const navigate = to => history.push(`/${ to }`);
    

    return ( 
        <>
            <ContainerAuthenticated> 

                <DashboardTitle>Conteúdo de vídeo</DashboardTitle>
    
            </ContainerAuthenticated> 
        </>
    );
}