import React, { useContext, useState } from "react";   
 
import DashboardSide from "../Side";
import { 
    DashboardHeaderContainer,
    DashboardHeaderAction,
    DashboardHeaderActionIcon,
    DashboardHeaderActionIconChevron,
    DashboardHeaderActionMenu,
    DashboardHeaderActionMenuItem,
    DashboardHeaderTitle
} from "./styled"; 
import { CoreContext } from "context/CoreContext";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { DoLogout } from "services/authentication";

export default function DashboardHeader(){  
    const history = useHistory(); 
    const navigate = to => history.push(`/${ to }`); 
    
    const [opened, setOpened] = useState(false) 
    const { user } = useContext(CoreContext)


    const options = [
        { title:"Meu Perfil", action: () => navigate('dashboard/me') },
        { title:"Meu Plano", action: () => navigate('dashboard/plan') },
        { title:"Ajuda", action: () => navigate('help') },
        { title:"Sair", action: () => exit() },        
    ]


    const exit = async () => {
        await DoLogout()
        navigate('login')
    }

    return ( 
        <> 
            <DashboardHeaderContainer>
                <DashboardHeaderTitle>
                    Royal Academy
                </DashboardHeaderTitle>
                <DashboardHeaderAction onClick={() => setOpened(!opened) }>
                    <DashboardHeaderActionIconChevron alt="menu-icon" />
                    { user?.name }
                    <DashboardHeaderActionIcon src={`/icons/teacher-pin.svg`} alt="menu-icon" />
                </DashboardHeaderAction> 
                <DashboardHeaderActionMenu opened={opened}>
                    {
                        options?.map((item, key) => 
                            <DashboardHeaderActionMenuItem key={key} onClick={item.action}>
                                { item?.title }
                            </DashboardHeaderActionMenuItem>
                        )
                    }
                </DashboardHeaderActionMenu>
                {/*  */}
            </DashboardHeaderContainer> 
        </>
    );
}